import React, { useEffect, useState } from "react";
import { Button, Icon, RSelect } from "../../../components/Component";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateAuctionDetails } from "../../../redux/features/auctionSlice/auctionDetailsSlice";
import { Emirates } from "../../../utils/Utils";
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import FormDateTime from '../../../components/form/FormDateTime'; // Assuming you use this
import FormSwitch from '../../../components/form/FormSwitch'; // Assuming you use this
import FormPriceInput from "../../../components/form/FormPriceInput";

export default function EditButtonWithModal({ auction }) {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { updateAuctionStatus, updateAuctionError } = useSelector((state) => state.auctionDetails);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    // Initialize react-hook-form with default values
    const methods = useForm({
        defaultValues: {
            price: auction?.item?.auction_starting_price?.toString() || '',
            startTime: auction?.start_time || '',
            endTime: auction?.end_time || '',
        }
    });

    const { handleSubmit, control, setValue, watch } = methods;

    const onSubmit = async (data) => {
        setLoading(true);
        let formData = {
            price: Number(data.price),
            start_time: data.startTime,
            end_time: data.endTime,
        };

        dispatch(updateAuctionDetails({ id: auction?.id, body: formData }));
    };

    useEffect(() => {
        if (updateAuctionStatus === "success") {
            toast.success("Auction updated successfully!");
            setLoading(false);
            toggle();
        } else if (updateAuctionStatus === "error") {
            toast.error(`Auction update failed, ${updateAuctionError}`);
            setLoading(false);
        }
    }, [updateAuctionStatus, updateAuctionError]);

    return (
        <div>
            <div className="me-3">
                <Button color="light" onClick={toggle} style={{ width: "100%" }}>
                    Edit
                </Button>
            </div>
            <Modal isOpen={isOpen} toggle={toggle}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader
                        toggle={toggle}
                        close={
                            <button className="close" onClick={toggle}>
                                <Icon name="cross" />
                            </button>
                        }
                    >
                        Edit Auction
                    </ModalHeader>
                    <ModalBody>
                        
                        <Col sm="12">
                            <div className="form-group mb-2">
                              <FormPriceInput name='price' label='Price' control={control}/>
                               
                            </div>
                        </Col>
                        <Col sm="12">
                            <div className="form-group mb-2">
                                <label className="form-label">Start Time</label>
                                <FormDateTime
                                    name="startTime"
                                    control={control}
                                />
                            </div>
                        </Col>
                        <Col sm="12">
                            <div className="form-group mb-2">
                                <label className="form-label">End Time</label>
                                <FormDateTime
                                    name="endTime"
                                    control={control}
                                />
                            </div>
                        </Col>
                        {auction.auction_type ==='LIVE'&& <Col sm="12" style={{ paddingTop: 12, paddingBottom: 12 }}>
                            <FormSwitch
                                name="enablePreBidding"
                                label="Enable Pre Bidding"
                                control={control}
                            />
                        </Col>}
                    </ModalBody>
                    <ModalFooter>
                        <Row>
                            <Col>
                                <Button color="light" onClick={toggle} style={{ width: "100%" }}>
                                    Cancel
                                </Button>
                            </Col>
                            <Col>
                                <Button color="primary" type="submit" style={{ width: "100%" }}>
                                    {loading ? (
                                        <>
                                            <Spinner size="sm" color="light" />
                                            Please Wait
                                        </>
                                    ) : (
                                        'Submit'
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </form>
            </Modal>
        </div>
    );
}
