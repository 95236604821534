import React from "react";
import DatePicker from "react-datepicker";
import { Controller } from "react-hook-form";

export default function FormDateTime({ control, name, label, ...props }) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={Date.now()}
      render={({ field, fieldState: { error } }) => {
        const value = new Date(field.value);
        return (
          <label style={{ width: "100%" }}>
            {label}
            <DatePicker
              className="form-control"
              selected={value}
              minDate={new Date()}
              dateFormat="MMMM d, yyyy h:mm aa"
              showTimeInput
              onChange={(e) => {
                field.onChange(e.getTime());
              }}
              {...props}
              style={{ width: "100%" }}
            />
            {error && <p style={{ color: 'red' }}>{error.message}</p>}
          </label>
        );
      }}
    />
  );
}
