import React, { useEffect, useState } from 'react';
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap';
import { Icon } from '../../../components/Component';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Stack } from '@mui/material';
import {
  cancelSelectedAuctions,
  fetchAuctions
} from '../../../redux/features/auctionSlice/auctionSlice';

export default function CancelSelectedAuctionsButton() {
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const selectedAuctions = useSelector((state) => state.auctions.selected);
  const loading = useSelector((state) => state.auctions.cancelAuctionsStatus);
  const error = useSelector((state) => state.auctions.cancelAuctionsError);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleCancelAuctions = async () => {
    try {
      await dispatch(cancelSelectedAuctions()).unwrap();
      // Refresh the auctions list after cancellation
      dispatch(fetchAuctions());
      toast.success('Auctions Cancelled Successfully');
      setIsOpen(false);
    } catch (err) {
      toast.error(`Failed to cancel auctions: ${error}`);
    }
  };

  return (
    <div>
      <div className="me-3">
        <Button
          variant="contained"
          onClick={toggle}
          disabled={selectedAuctions.length === 0}
          color="error"
        >
          Cancel Selected ({selectedAuctions.length})
        </Button>
      </div>
      <Modal isOpen={isOpen}>
        <ModalHeader
          close={
            <button className="close" onClick={toggle} type="button">
              <Icon name="cross" />
            </button>
          }
        >
          Cancel Selected Auctions
        </ModalHeader>
        <ModalBody>
          <div className="text-center mb-3">
            <Icon name="alert-circle" className="text-danger" size="lg" />
          </div>
          <p className="text-center">
            Are you sure you want to cancel {selectedAuctions.length} selected
            auction{selectedAuctions.length !== 1 ? 's' : ''}?
          </p>
          <p className="text-center text-muted small">
            This action cannot be undone.
          </p>
        </ModalBody>
        <ModalFooter>
          <Stack direction="row" justifyContent="end">
            <Button
              color="inherit"
              onClick={toggle}
              style={{ width: '100%' }}
              type="button"
            >
              Cancel
            </Button>

            <Button
              color="error"
              onClick={handleCancelAuctions}
              style={{ width: '100%' }}
              disabled={loading == 'loading'}
            >
              {loading == 'loading' ? <Spinner size="sm" /> : 'Confirm Cancel'}
            </Button>
          </Stack>
        </ModalFooter>
      </Modal>
    </div>
  );
}
