import React from 'react';
import { useForm } from 'react-hook-form';
import FormTextField from '../../../components/form/FormTextField'; // Using your custom form component for text fields
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid2,
  Stack,
  Typography
} from '@mui/material';
import FormPhoneField from '../../../components/form/FormPhoneField';

export default function DealerContactForm({
  dealer,
  onSubmit,
  toggleEdit,
  editMode
}) {

  const { control, handleSubmit } = useForm({
    defaultValues: {
      contact_no: dealer?.contact_no || '',
      whatsapp_no: dealer?.whatsapp_no || '',
     
    }
  });

  const handleFormSubmit = (data) => {
    
    if (editMode) {
      onSubmit(data);
      toggleEdit('contact');  // or whatever section name is appropriate
    }
  };

  return (
   
      <Card sx={{ mb: 2 }}>
        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <CardHeader title={'Contact Info'} />
          <Box sx={{ pr: 2 }}>
            {editMode ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                type="button"
                onClick={handleSubmit(handleFormSubmit)}
              >
                Save Changes
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                type='button'
                onClick={() => toggleEdit('contact')}
              >
                Edit
              </Button>
            )}
          </Box>
        </Stack>
        <CardContent>
          <Grid2 container spacing={2}>
            <Grid2 item size={6}>
              {editMode ? (
                <FormPhoneField
                  name={'contact_no'}
                  label={'Contact No.'}
                  control={control}
                />
              ) : (
                <Typography>
                  <strong>Contact No. :</strong>{dealer?.whatsapp_no?.dial_code} {dealer?.contact_no?.number}
                </Typography>
              )}
            </Grid2>
            <Grid2 item size={6}>
              {editMode ? (
                <FormPhoneField
                  name={'whatsapp_no'}
                  label={'WhatsApp Number'}
                  control={control}
                />
              ) : (
                <Typography>
                  <strong>WhatsApp Number :</strong>{dealer?.whatsapp_no?.dial_code} {dealer?.whatsapp_no?.number}
                </Typography>
              )}
            </Grid2>

            <Grid2 item size={6}>
              {editMode ? (
                <FormPhoneField
                  name={'email'}
                  label={'Email'}
                  control={control}
                />
              ) : (
                <Typography>
                  <strong>Email :</strong> {dealer?.email}
                </Typography>
              )}
            </Grid2>
           
          </Grid2>
        </CardContent>
      </Card>
  );
}
