import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Block,
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  TooltipComponent,
  PaginationComponent,
  PreviewAltCard,
  RSelect,
  InputSwitch,
} from "../../components/Component";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
  Badge,
  Spinner,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  debouncedFetchUsers,
  fetchUsers,
  setAllSelected,
  setSelected,
} from "../../redux/features/usersSlice/usersSlice";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import TableHeader from "../../components/table/TableHeader";
import ReactDatePicker from "react-datepicker";
import DateFilter from "../../components/date-filter/DateFilter";
import ListBlockHeader from "../../components/list-block-header/ListBlockHeader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput, { isValidPhoneNumber, isPossiblePhoneNumber, formatPhoneNumber } from 'react-phone-number-input';
import { createUser, updateUserDetails } from "../../redux/features/usersSlice/userDetails";
import 'react-phone-number-input/style.css';
import { fetchRoles } from "../../redux/features/usersSlice/rolesSlice";
import ConfirmModal from "../verticals/components/ConfirmModal";

import { Emirates, Roles, UserRegisterTypes, UserVerificationStatus, formatCreatedAtDateTime, validateEmail } from "../../utils/Utils";
import { fetchRM } from "../../redux/features/usersSlice/rmSlice";
import { parsePhoneNumber } from "react-phone-number-input";
import { startCase } from "lodash";
import ImageUpload from "../cars/components/ImageUpload";
import InputFile from "../cars/components/InputFile";
import { uploadToS3 } from "../../utils/uploadImageToS3";
import AddDealerForm from "./addDealerForm";



const DealersData = () => {
  const navigate = useNavigate();
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);

  const [filter, setFilter] = useState(null);
  const [sortByField, setSortByField] = useState("createdAt");
  const [sortByValue, setSortByValue] = useState(-1);
  const [dateFilter, setDateFilter] = useState(null);
  const [user, setUser] = useState(null);
  const [value, setValue] = useState();
  const [whatsapp, setWhatsapp] = useState();
  const [city, setCity] = useState();
  const [showModal, setShowModal] = useState(false);
  const loggedInUser = useSelector((state) => state?.auth?.loggedInUser);

  const loggedInUserRoles = loggedInUser?.roles
  let myRole = '';
  if (loggedInUserRoles.some(role => role.name === Roles.SuperAdmin)) {
    myRole = 'SuperAdmin';
  } else if (loggedInUserRoles.some(role => role.name === Roles.Admin)) {
    myRole = 'Admin';
  } else if (loggedInUserRoles.some(role => role.name === Roles.RM)) {
    myRole = 'RM';
  }
  const handleDelete = () => {
    dispatch(updateUserDetails({ id: user.id, body: { "isActive": active } }));
    closeModal();
    navigate(0)
  }
  const closeModal = () => {
    setShowModal(false);
    setUser(null);
  };
  const handleModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };
  const [fullName, setFullName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [tradeLicense, setTradeLicense] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  // const [password, setPassword] = useState('');
  // const [cpassword, setCPassword] = useState('');
  const [errorX, setErrorX] = useState(null);

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const [role, setRole] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);

  const [rangeStart, setStart] = useState(null);
  const [rangeEnd, setEnd] = useState(null);
  const [phoneVerified, setPhoneVerified] = useState(null);
  const [emailVerified, setEmailVerified] = useState(null);

  const [active, setActive] = useState(null);
  const [optRM, setOptRM] = useState([]);
  const [selectedRM, setSelectedRM] = useState({});

  const rmGet = localStorage.getItem('rmGetValue') || '';

  const typeGet = localStorage.getItem('typeGetValue') || '';
  const [type, setType] = useState(typeGet);
  const dateOptions = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
  const timeOptions = { hour: '2-digit', minute: '2-digit' };

  useEffect(() => {
    if (rangeEnd && rangeStart) {
      setDateFilter({ startDate: rangeStart.toISOString(), endDate: rangeEnd.toISOString() });
    }
  }, [rangeEnd, rangeStart]);

  useEffect(() => {
    setFullName(user?.full_name)
    setCity({ label: user?.city, value: user?.city });
  }, [user]);

  const setRangeStart = (e) => {
    setStart(e);
    // if (rangeEnd) {
    //   onChange({ startDate: rangeStart.toISOString(), endDate: rangeEnd.toISOString() });
    // }
  };
  const setRangeEnd = (e) => {
    setEnd(e);
    // if (rangeStart) {
    //   onChange({ startDate: rangeStart.toISOString(), endDate: rangeEnd.toISOString() });
    // }
  };
  const { data, totalCount, loading, perPage } = useSelector((state) => state?.users);
  const relManagers = useSelector((state) => state?.rm);

  const roles = useSelector((state) => state?.roles?.data);
  let roleOptions = [];
  if (myRole !== Roles.SuperAdmin) {
    roleOptions = roles?.filter((role) =>
      role.name !== 'Dealer' &&
      role.name !== 'ServiceProvider' &&
      role.name !== 'System_User' &&
      role.name !== 'SuperAdmin'
    )
      .map((role) => ({ "label": role.name, "value": role.id }))
  }
  else {
    roleOptions = roles?.filter((role) =>
      role.name !== 'Dealer' &&
      role.name !== 'ServiceProvider' &&
      role.name !== 'System_User'
    )
      .map((role) => ({ "label": role.name, "value": role.id }))
  }

  let roleOptionsEdit = roles?.filter((role) =>
    role.name !== 'Dealer' &&
    role.name !== 'ServiceProvider' &&
    role.name !== 'SuperAdmin' &&
    role.name !== 'Concierge' &&
    role.name !== 'System_User'
  ).map((role) => ({ "label": role.name, "value": role.id }))

  const [listData, setData] = useState([]);

  useEffect(() => {
    if (data) {
      setData(data.data);
    }
  }, [data]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchRoles());
    dispatch(fetchRM());
  }, []);

  useEffect(() => {
    let options = [];
    let data = relManagers.data
    data &&
      data.length > 0 &&
      data
        .map((a) =>
          options.push({
            value: a.id,
            label: a?.full_name
          })
        );
    setOptRM(options);
  }, [relManagers.data]);


  // Changing state value when searching name
  useEffect(() => {
    let data = {
      "query": { "isDeleted": false, "user_register_type": type },
      "options": {
        page: currentPage,
        limit: perPage,
        sort: sortByValue === 1 ? sortByField : '-' + sortByField,

      }
    }
    // { page: currentPage, status: filter, type, sortBy: { [sortByField]: sortByValue } };
    if (dateFilter) {
      data.query.date = dateFilter;
    }

    if (filter === 'Active') {
      setCurrentPage(1);
      data.query.isActive = true;
    }
    else if (filter === 'Inactive') {
      setCurrentPage(1);
      data.query.isActive = false;
    }
    if ((Object.keys(selectedRM).length !== 0)) {

      data.query.assignedRM = selectedRM.value;
    }
    else if (rmGet) {
      data.query.assignedRM = JSON.parse(rmGet).value;
    }

    if (onSearchText !== "") {
      data.query.search = onSearchText.trim();
      dispatch(debouncedFetchUsers(data));
    } else {
      dispatch(fetchUsers(data));
    }
  }, [onSearchText.trim(), currentPage, filter, type, sortByValue, dateFilter, selectedRM, rmGet]);

  const selectorCheck = (e) => {
    dispatch(setAllSelected({ value: e.currentTarget.checked }));
  };

  const onSelectChange = (e, index) => {
    dispatch(setSelected({ index, value: e.currentTarget.checked }));
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };
  const setSort = (value) => {
    if (sortByField === value) {
      setSortByValue(sortByValue * -1);
    } else {
      setSortByField(value);
      setSortByValue(-1);
    }
  };

  // Get current list, pagination

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const { errors, register, handleSubmit } = useForm();
  const toggleModal = () => {
    setOpen(!open);
  };
  const toggleEditModal = () => {
    setEdit(!edit);
  };

  const validate = (fullName, email, role) => {
    let isError = false;
    if (fullName.length < 2) {
      toast.error('Enter valid First Name');
      isError = true;
    }
    if (!validateEmail(email)) {
      isError = true;
      toast.error('Enter valid email address');
    }

    if (!value || !value.trim()) {
      isError = true;
      toast.error('Enter valid phone number');

      const isPossiblePhone = isPossiblePhoneNumber(value, 'AE');

      if (isPossiblePhone) {
        isError = true;
        const isValidNum = isValidPhoneNumber(value, 'AE');

        if (isValidNum) {
          isError = false;
        }
      } else {
        isError = true;
        toast.error('Phone number is not possible');
      }
    }

    if (!role || role?.length === 0) {
      isError = true;
      toast.error('Select the Role');

    }

    return isError ? false : true;
  };
  // Add User Info in DB
  const addMember = async (e) => {
    e.preventDefault();
    if (
      !validate(fullName, email, role)
    ) {
      return;
    }

    // console.log(value)
    const formData = {
      full_name: fullName,
      email,
      city: city?.value,
      phone: {
        "code": "ae",
        "dial_code": "+971",
        "number": value.slice(4),
      },
      whatsapp: {
        "code": "ae",
        "dial_code": "+971",
        "number": value.slice(4),
      },
      is_phone_verified: true,
      is_email_verified: true,
      is_verified: "approved",
      role: role.map(r => r.value),
      user_type: 2,
      user_register_type: 'Dealer',
      dealer_type: "Freelancer",
    };
    try {
      setLoadingUser(true);
      dispatch(createUser(formData))
      setLoadingUser(false);
      // navigate(0);

    } catch (err) {
      // console.log(err)
      // toast.error('Server error. Try Again !');
    }
  };
  const editMember = async (e) => {
    e.preventDefault();

    // console.log(value)
    const formData = {
      is_phone_verified: phoneVerified,
      is_email_verified: emailVerified,
      full_name: fullName,
      city: city?.value,
    };
    if (email) {
      formData.email = email;
    }
    if (value) {
      // console.log(formatPhoneNumber(value))
      const phoneNumber = parsePhoneNumber(value)
      // console.log(phoneNumber)
      if (phoneNumber) {
        let myPhone = {
          "code": phoneNumber.country.toLowerCase(),
          "dial_code": '+' + phoneNumber.countryCallingCode,
          "number": phoneNumber.nationalNumber,
        }
        formData.phone = myPhone;
      }

    }
    if (role) {
      formData.role = role.map(r => r.value);
    }
    if (whatsapp) {
      // console.log(formatPhoneNumber(value))
      const phoneNumber = parsePhoneNumber(value)
      // console.log(phoneNumber)
      if (phoneNumber) {
        let myPhone = {
          "code": phoneNumber.country.toLowerCase(),
          "dial_code": '+' + phoneNumber.countryCallingCode,
          "number": phoneNumber.nationalNumber,
        }
        formData.whatsapp = myPhone;
      }
    }

    try {
      setLoadingUser(true);
      dispatch(updateUserDetails({ id: user?.id, body: formData }));
      setPhoneVerified(null);
      setEmailVerified(null);
      setUser(null);
      setEdit(false)
      setLoadingUser(false);
      // navigate(0);

    } catch (err) {
      // console.log(err)
      // toast.error('Server error. Try Again !');
    }
  };
  let clearBadge = type || filter || onSearchText !== '' || rangeEnd || rangeStart || (Object.keys(selectedRM).length !== 0 || rmGet);

  return (
    <React.Fragment>
      <Head title="Dealers List"></Head>
      <Content>
        {/* <ListBlockHeader title="Users" onSearchText={onSearchText} onSearchChanged={onFilterChange} onDateChanged={setDateFilter}> */}
        <ListBlockHeader msg={totalCount ? `Total dealers: ${totalCount}` : ""} title="Dealers" placeholder="Search by name, email or phone" onSearchText={onSearchText} onSearchChanged={onFilterChange} onDateChanged={setDateFilter}>
          <div style={{ flexShrink: 0, flexGrow: 1 }}>
            <div className="form-control-wrap">
              <div className="input-daterange date-picker-range input-group">
                <ReactDatePicker
                  selected={rangeStart}
                  onChange={setRangeStart}
                  selectsStart
                  startDate={rangeStart}
                  endDate={rangeEnd}
                  maxDate={rangeEnd ?? new Date()}
                  wrapperClassName="start-m"
                  className="form-control"
                />{" "}
                <div className="input-group-addon">TO</div>
                <ReactDatePicker
                  selected={rangeEnd}
                  onChange={setRangeEnd}
                  startDate={rangeStart}
                  endDate={rangeEnd}
                  selectsEnd
                  minDate={rangeStart}
                  maxDate={new Date()}
                  wrapperClassName="end-m"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          {" "}
          <UncontrolledDropdown>
            <DropdownToggle
              color="transparent"
              className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
            >
              {(Object.keys(selectedRM).length !== 0) ? selectedRM?.label : rmGet ? (JSON.parse(rmGet).label) : "RM"}
            </DropdownToggle>
            <DropdownMenu end>
              <ul className="link-list-opt no-bdr">
                <li>
                  <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => {
                    setSelectedRM({})
                    localStorage.removeItem('rmGetValue')
                  }
                  }>
                    <span>ALL</span>
                  </DropdownItem>
                </li>
                {optRM?.map((rm, index) =>
                  <li key={index}>
                    <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => {
                      setSelectedRM(rm)
                      const selectedOptionString = JSON.stringify(rm);
                      localStorage.setItem("rmGetValue", selectedOptionString);
                    }
                    }

                    >
                      <span>{rm.label}</span>
                    </DropdownItem>
                  </li>
                )}

              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown>
            <DropdownToggle
              color="transparent"
              className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
            >
              {filter ? filter : "Status"}
            </DropdownToggle>
            <DropdownMenu end>
              <ul className="link-list-opt no-bdr">
                <li>
                  <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => setFilter("Active")}>
                    <span>Active</span>
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => setFilter("Inactive")}>
                    <span>Inactive</span>
                  </DropdownItem>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>

          <Button color="primary"
            onClick={(ev) => {
              ev.preventDefault();
              toggleModal();
            }}
          >
            <Icon name="plus"></Icon>
            <span>Add Dealer</span>
          </Button>

        </ListBlockHeader>
        <Col xs="12" className="mt-2 d-flex justify-content-end pe-4">
          {rmGet && (
            <div className="selected-options d-flex flex-wrap">
              <Badge
                className="selected-option bg-primary me-1"
                color="primary"
              >
                {(JSON.parse(rmGet).label)}
                <Icon
                  name="cross-sm"
                  className="ms-1"
                  onClick={() => {
                    setSelectedRM({});
                    localStorage.removeItem('rmGetValue')
                  }}
                />
              </Badge>
            </div>
          )}
          {clearBadge && (
            <Badge className="selected-option bg-primary me-1" color="danger">
              {'Clear All'}
              <Icon
                name="cross-sm"
                className="ms-1"
                onClick={() => {
                  setType('');
                  setFilter(null);
                  setSearchText('');
                  setRangeEnd(null);
                  setRangeStart(null);
                  setDateFilter(null);
                  setSelectedRM({});
                  localStorage.removeItem('rmGetValue')
                  localStorage.removeItem('typeGetValue')
                }}
              />
            </Badge>
          )}
        </Col>
        <Block>
          <div className="nk-tb-list is-separate is-medium mb-3">
            <DataTableHead className="nk-tb-item">
              <DataTableRow className="nk-tb-col-check">
                <div className="custom-control custom-control-sm custom-checkbox notext">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="pid-all"
                    onChange={(e) => selectorCheck(e)}
                  />
                  <label className="custom-control-label" htmlFor="pid-all"></label>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Name</span>
              </DataTableRow>
              {/* <DataTableRow>
                <TableHeader name="Name" setSort={setSort} sortByField={sortByField} sortByValue={sortByValue} />
              </DataTableRow> */}

              {/* <DataTableRow size="md">
                <TableHeader name="lastName" setSort={setSort} sortByField={sortByField} sortByValue={sortByValue} />
              </DataTableRow> */}
              <DataTableRow size="md">
                <span className="sub-text">Phone</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Email</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">RM</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">User register type</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Roles</span>
              </DataTableRow>
              <DataTableRow size="md">
                <TableHeader name="createdAt" setSort={setSort} sortByField={sortByField} sortByValue={sortByValue} />
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">Status</span>
              </DataTableRow>

              <DataTableRow className="nk-tb-col-tools">
                <ul className="nk-tb-actions gx-1 my-n1">
                  {/* <li>
                    <UncontrolledDropdown>
                      <DropdownToggle tag="a" className="btn btn-trigger dropdown-toggle btn-icon me-n1">
                        <Icon name="more-h"></Icon>
                      </DropdownToggle>
                      <DropdownMenu end>
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#markasdone"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <Icon name="truck"></Icon>
                              <span>Mark As Delivered</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#remove"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <Icon name="trash"></Icon>
                              <span>Remove Orders</span>
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li> */}
                </ul>
              </DataTableRow>
            </DataTableHead>

            {listData?.length > 0
              ? listData.map((item, index) => (
                <DataTableItem key={item.id} onClick={() => {
                  navigate(`/users/${item.id}`);
                }}>
                  <DataTableRow className="nk-tb-col-check">
                    <div className="custom-control custom-control-sm custom-checkbox notext">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        defaultChecked={item.check}
                        id={item.id + "oId-all"}
                        key={Math.random()}
                        onChange={(e) => onSelectChange(e, index)}
                      />
                      <label className="custom-control-label" htmlFor={item.id + "oId-all"}></label>
                    </div>
                  </DataTableRow>

                  <DataTableRow
                  >
                    <Link to={`/users/${item.id}`}>{startCase(item?.full_name)}{" "}
                      {item.is_verified === UserVerificationStatus.APPROVED ?
                        <Icon
                          className={`text-success`}
                          name="check-circle"></Icon>
                        : null}
                    </Link>
                  </DataTableRow >
                  {/* <DataTableRow>{item.lastName}</DataTableRow> */}
                  <DataTableRow
                  >{item.phone?.dial_code}{item.phone?.number}{" "}
                    {item.is_phone_verified ?
                      <Icon
                        className={`text-success`}
                        name="check-circle"></Icon>
                      : null}
                  </DataTableRow>
                  <DataTableRow

                  >{item.email}{" "}
                    {item.is_email_verified ?
                      <Icon
                        className={`text-success`}
                        name="check-circle"></Icon>
                      : null}
                  </DataTableRow>
                  <DataTableRow>{item.assignedRM?.full_name ?? ""}
                  </DataTableRow>
                  <DataTableRow>{item?.user_register_type} {item?.dealer_type && `(${item?.dealer_type})`}</DataTableRow>
                  <DataTableRow>
                    {item?.roles?.map((e) => (
                      <div key={e.id}>{e.name}</div>
                    ))}
                  </DataTableRow>

                  <DataTableRow size="md">
                    <span>
                      {item?.createdAt && new Date(item?.createdAt).toLocaleDateString(undefined, dateOptions)} {new Date(item?.createdAt).toLocaleTimeString(undefined, timeOptions)}
                      {/* {new Date(item.createdAt).toDateString()} */}
                      {/* {formatCreatedAtDateTime((item?.createdAt))} */}
                    </span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className={`dot bg-${item.isActive === true ? "success" : "warning"} d-sm-none`}></span>
                    <Badge
                      className="badge-sm badge-dot has-bg d-none d-sm-inline-flex"
                      color={item.isActive === true ? "success" : "warning"}
                    >
                      {item.isActive ? "Active" : "Inactive"}
                    </Badge>
                  </DataTableRow>

                  <DataTableRow className="nk-tb-col-tools">
                    <ul className="nk-tb-actions gx-1">
                      <li className="nk-tb-action-hidden"
                        onClick={() => {
                          navigate(`/users/${item?.id}`);
                          window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                          }); // Scroll to the top of the page
                        }}
                      >
                        <TooltipComponent
                          tag="a"
                          containerClassName="btn btn-trigger btn-icon"
                          id={"view" + item.id}
                          icon="eye"
                          direction="top"
                          text="View Details"
                        />
                      </li>
                      <li>
                        <UncontrolledDropdown onClick={(ev) => ev.stopPropagation()}>
                          <DropdownToggle tag="a" className="btn btn-icon dropdown-toggle btn-trigger">
                            <Icon name="more-h"></Icon>
                          </DropdownToggle>
                          <DropdownMenu end>
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdown"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setUser(item);
                                    setPhoneVerified(item.is_phone_verified)
                                    setEmailVerified(item.is_email_verified)
                                    setRole(item?.roles.map(r => ({ "label": r.name, "value": r.id })))
                                    toggleEditModal();
                                  }}
                                >
                                  <Icon name="eye"></Icon>
                                  <span>Edit</span>
                                </DropdownItem>
                              </li>
                              {/* {item.isActive === true && (
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#dropdown"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="truck"></Icon>
                                      <span>Approve</span>
                                    </DropdownItem>
                                  </li>
                                )} */}
                              <li>
                                {item?.isActive ?

                                  <DropdownItem
                                    tag="a"
                                    href="#dropdown"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setUser(item);
                                      setActive(false);
                                      setShowModal(true);
                                    }}
                                  >

                                    <Icon name="trash"></Icon>
                                    <span>Deactivate User</span>
                                  </DropdownItem>
                                  :
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdown"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setUser(item);
                                      setActive(true);
                                      setShowModal(true);
                                    }}
                                  >

                                    <Icon name="pen2"></Icon>
                                    <span>Activate User</span>

                                  </DropdownItem>
                                }
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </li>
                    </ul>
                  </DataTableRow>
                </DataTableItem>
              ))
              : null}
          </div>
          <PreviewAltCard>
            {listData?.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={totalCount ?? 0}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : loadingUser ? (
              <div className="text-center">
                <Spinner color="primary" />
              </div>
            ) : (
              <div className="text-center">
                <span className="text-silent">No Users found</span>
              </div>
            )}
          </PreviewAltCard>
          <Modal
            size="md"
            isOpen={open}
            toggle={toggleModal}
            backdrop="static"
          >
            <ModalHeader toggle={toggleModal}>
              Add New User
              <button className="close" onClick={toggleModal}></button>
            </ModalHeader>
            <ModalBody>
              {/* <form onSubmit={(e) => addMember(e)}>

                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Company Name*
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="company-name"
                        className="form-control"
                        name="companyName"
                        required
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>

                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Full Name*
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="full-name"
                        className="form-control"
                        name="fullName"
                        required
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Email*{' '}
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="email"
                        id="first-name"
                        className="form-control"
                        name="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Phone*{' '}
                    </label>
                    <PhoneInput
                      className="phoneInput "
                      defaultCountry="AE"
                      value={value}
                      name="phone"
                      placeholder="Enter Your Phone *"
                      onChange={(value) => setValue(value)}
                      onBlur={(e) => {
                        const { value } = e.target;

                        const isPossiblePhone = isPossiblePhoneNumber(
                          value,
                          'AE'
                        );

                        if (isPossiblePhone) {
                          const isValidNum = isValidPhoneNumber(value, 'AE');

                          if (isValidNum) {
                            setErrorX(null);
                            return;
                          }

                          setErrorX('Number is not valid');
                          return;
                        }

                        setErrorX('Number is not possbible');
                      }}
                    ></PhoneInput>
                    <div className="text-black flex"> {errorX && errorX}</div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Emirate
                    </label>
                    <div className="form-control-wrap">
                      <RSelect
                        isMulti={false}
                        value={city}
                        onChange={(e) => setCity(e)}
                        options={Emirates.map(e => ({ "label": e, "value": e }))}
                      />

                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Trading License
                    </label>
                    <div className="form-control-wrap">
                      <InputFile

                        handleFileChange={async (e) => {
                          const s3Url = await uploadToS3(e.target.files[0],)
                          setTradeLicense(s3Url)
                        }}

                      />

                    </div>
                  </div>
                </Col>

                <Col sm="6">
                  <Button
                    type="submit"
                    className="bg-primary text-white mt-2"
                  >
                    {loadingUser ? (
                      <>
                        <Spinner size="sm" color="light" />
                        Please Wait
                      </>
                    ) : (
                      'Submit'
                    )}
                  </Button>
                </Col>
              </form> */}
              <AddDealerForm setOpen={toggleModal} />
            </ModalBody>
          </Modal>
          <Modal
            size="md"
            isOpen={edit}
            toggle={toggleEditModal}
            backdrop="static"
          >
            <ModalHeader toggle={toggleEditModal}>
              Edit User
              <button className="close" onClick={toggleEditModal}></button>
            </ModalHeader>
            <ModalBody>
              <form onSubmit={(e) => editMember(e)}>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Full Name*
                    </label>
                    <div className="form-control-wrap">
                      <input

                        type="text"
                        id="full-name"
                        className="form-control"
                        name="fullName"
                        required
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}

                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Email*{' '}
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="email"
                        id="first-name"
                        className="form-control"
                        name="email"
                        disabled={myRole && myRole !== Roles.SuperAdmin}
                        defaultValue={user?.email}
                        onChange={(e) => setEmail(e.target.value)}

                      />
                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Phone*{' '}
                    </label>
                    <div className="form-control-wrap">
                      {myRole && myRole === Roles.SuperAdmin ?
                        <PhoneInput
                          className="phoneInput "
                          value={user?.phone?.dial_code + user?.phone?.number}
                          name="phone"
                          placeholder="Enter Your Phone *"
                          onChange={(value) => setValue(value)}
                        // onBlur={(e) => {
                        //   const { value } = e.target;
                        //   const isPossiblePhone = isPossiblePhoneNumber(value, "AE");

                        //   if (isPossiblePhone) {
                        //     const isValidNum = isValidPhoneNumber(value, "AE");

                        //     if (isValidNum) {
                        //       setErrorX(null);
                        //       return;
                        //     }

                        //     setErrorX("Number is not valid");
                        //     return;
                        //   }

                        //   setErrorX("Number is not possbible");
                        // }}
                        ></PhoneInput>
                        :
                        <input
                          type="phone"
                          id="phone"
                          className="form-control"
                          name="phone"
                          disabled
                          defaultValue={user?.phone?.dial_code + user?.phone?.number}
                        />}
                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Whatsapp*{' '}
                    </label>
                    <PhoneInput
                      className="phoneInput "
                      value={user?.whatsapp?.dial_code + user?.whatsapp?.number}
                      name="phone"
                      placeholder="Enter Your Phone *"
                      onChange={(value) => setWhatsapp(value)}
                    // onBlur={(e) => {
                    //   const { value } = e.target;
                    //   const isPossiblePhone = isPossiblePhoneNumber(value, "AE");

                    //   if (isPossiblePhone) {
                    //     const isValidNum = isValidPhoneNumber(value, "AE");

                    //     if (isValidNum) {
                    //       setErrorX(null);
                    //       return;
                    //     }

                    //     setErrorX("Number is not valid");
                    //     return;
                    //   }

                    //   setErrorX("Number is not possbible");
                    // }}
                    ></PhoneInput>
                    <div className="text-black flex"> {errorX && errorX}</div>
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="customer">
                      Role
                    </label>
                    <div className="form-control-wrap">
                      <div className="form-control-wrap">
                        <RSelect
                          isMulti
                          value={role}
                          options={roleOptionsEdit}
                          onChange={(e) => setRole(e)}
                          className="customInput"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="first-name">
                      Emirate
                    </label>
                    <div className="form-control-wrap">
                      <RSelect
                        isMulti={false}
                        value={city}
                        onChange={(e) => setCity(e)}
                        options={Emirates.map(e => ({ "label": e, "value": e }))}
                      />

                    </div>
                  </div>
                </Col>
                <Row>
                  <Col sm="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="customer">
                        Phone verified
                      </label>
                      <div className="form-control-wrap">
                        <InputSwitch
                          checked={phoneVerified}
                          id="phone_verified-"
                          onClick={(e) => setPhoneVerified(e)} />
                      </div>

                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="customer">
                        Email verified
                      </label>
                      <div className="form-control-wrap">
                        <InputSwitch
                          checked={emailVerified}
                          id="email_verified-"
                          onClick={(e) => setEmailVerified(e)} />
                      </div>

                    </div>
                  </Col>
                </Row>

                <Col sm="6">
                  <Button
                    type="submit"
                    className="bg-primary text-white mt-2"
                  >
                    {loadingUser ? (
                      <>
                        <Spinner size="sm" color="light" />
                        Please Wait
                      </>
                    ) : (
                      'Submit'
                    )}
                  </Button>
                </Col>
              </form>
            </ModalBody>
          </Modal>
          {/* ****** Modal Start ****** */}
          {showModal ? (
            <ConfirmModal
              msg={"update this user?"}
              name={""}
              open={showModal}
              loading={loading}
              handleClick={handleModal}
              handleConfirm={handleDelete}
              handlecloseModal={closeModal}
            />
          ) : null}
          {/* ****** Modal End ****** */}

        </Block>
      </Content>
    </React.Fragment>
  );
};

export default DealersData;
