import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { debounce } from "lodash";

const initialState = {
  data: [],
  totalCount: 0,
  perPage: 10,
  loading: false,
  error: null,
};
const fetchAllPayments = createAsyncThunk("paymentDetails", (data) => {
  let url = `/device/api/v1/paymenttransaction/dealer/`;

  // console.log(url);
  return axios.get(url, {params: data}).then((response) => {
    return response.data;
  });
});
export const clearPaymentDetails = createAsyncThunk('clearPaymentDetails', async () => {
  return null; // You can return any value here as it won't be used.
});


const paymentDetailsSlice = createSlice({
  name: "paymentDetails",
  initialState,
  reducers: {
    resetUpdateStatus: (state) => {
      state.updateStatus = "init";
      state.updateError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllPayments.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = {};
    });
    builder.addCase(fetchAllPayments.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload.data;
      state.totalCount = action.payload?.data?.paginator?.itemCount;
      state.perPage = action.payload?.data?.paginator?.perPage;
    });
    builder.addCase(fetchAllPayments.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = action.error.message;
    });
    builder.addCase(clearPaymentDetails.fulfilled, (state) => {
      // Handle clearPaymentDetails fulfilled action
      state.data = {}; // Clear payment details

    });
  },
});
const debounced = debounce(({ ...arg }, dispatch) => dispatch(fetchAllPayments({ ...arg })), 500);
const fetchDebouncedAllPayments =
  ({ ...args }) =>
    (dispatch) =>
      debounced({ ...args }, dispatch);
export default paymentDetailsSlice.reducer;
export const { resetUpdateStatus } = paymentDetailsSlice.actions;
export { fetchAllPayments, fetchDebouncedAllPayments };
