import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Spinner } from "reactstrap";
import { getImage } from "../../utils/uploadImageToS3";

// Create a cache outside the component to persist across renders
const imageCache = new Map();

// Custom hook for image loading with caching
const useImageLoader = (imageKey) => {
  const [state, setState] = useState({
    loading: false,
    error: null,
    imageUrl: imageCache.get(imageKey) || null
  });

  useEffect(() => {
    const loadImage = async () => {
      // If image is already in cache, use it
      if (imageCache.has(imageKey)) {
        setState({
          loading: false,
          error: null,
          imageUrl: imageCache.get(imageKey)
        });
        return;
      }

      // If no image key or it's the default image, skip loading
      if (!imageKey || imageKey === "defaults/default_image.png") {
        setState({
          loading: false,
          error: null,
          imageUrl: null
        });
        return;
      }

      setState(prev => ({ ...prev, loading: true }));

      try {
        const url = await getImage(imageKey);
        if (url) {
          imageCache.set(imageKey, url);
          setState({
            loading: false,
            error: null,
            imageUrl: url
          });
        }
      } catch (error) {
        setState({
          loading: false,
          error,
          imageUrl: null
        });
        console.error('Error loading image:', error);
      }
    };

    loadImage();
  }, [imageKey]);

  return state;
};

const Image = ({ 
  className, 
  size, 
  width, 
  image, 
  imageAlt,
  loadingComponent = <Spinner />,
  defaultImage = "defaults/default_image.png",
  ...props 
}) => {
  const classes = classNames({
    [`${className}`]: className,
  });

  const { loading, imageUrl } = useImageLoader(image);

  // Early return for loading state
  if (loading) {
    return (
      <div className={classes}>
        {loadingComponent}
      </div>
    );
  }

  // Early return if no image URL
  if (!imageUrl) {
    return <div className={classes}>{props.children}</div>;
  }

  return (
    <div className={classes}>
      <img
        src={imageUrl}
        alt={imageAlt}
        style={{
          objectFit: "cover",
          objectPosition: "center",
          height: size,
          width: width
        }}
        loading="lazy" // Enable lazy loading
      />
      {props.children}
    </div>
  );
};

// Memoize the component to prevent unnecessary re-renders
export default React.memo(Image, (prevProps, nextProps) => {
  return prevProps.image === nextProps.image &&
         prevProps.size === nextProps.size &&
         prevProps.width === nextProps.width &&
         prevProps.className === nextProps.className;
});