import React, { Suspense, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Homepage from '../pages/Homepage';
import UsersData from '../pages/users/users';
import AuctionDetails from '../pages/auctions/AuctionDetails';
import CarDetails from '../pages/cars/CarDetails';
import UserDetails from '../pages/users/UserDetails';


import Concierge from '../pages/auctions/Concierge';

import AllItems from '../pages/items/AllItems';
import Payments from '../pages/payments/Payments';
import LeadsData from '../pages/users/Leads';
import NoBidsAuctions from '../pages/auctions/NoBidsAuctions';
import RejectedAuctions from '../pages/auctions/RejectedAuctions';
import StaffData from '../pages/users/staff';
import Reviews from '../pages/users/Reviews';
import ConfirmedAuctions from '../pages/auctions/ConfirmedAuctions';

import SellerRejectedAuctions from '../pages/auctions/SellerRejectedAuctions';
import Shipments from '../pages/shipments/Shipments';
import DealersData from '../pages/dealers/dealers';
import MyAuctions from '../pages/auctions/MyAuctions';
import AddItem from '../pages/cars/AddItem';
import AuctionGroups from '../pages/auctionGroups/auctionGroups';
import AuctionGroupDetails from '../pages/auctionGroups/auctionGroupDetails';
import DirectSaleItems from '../pages/directSales/directSales';
import DirectSaleDetails from '../pages/directSales/directSaleDetails';
import OrdersListPage from '../pages/orders/OrdersList';
import OrderDetailsPage from '../pages/orders/OrderDetailsPage';
import SettingsPage from '../pages/settings/Settings';
import CSVUploader from '../pages/cars/BulkAddPage';

const Pages = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense fallback={<div />}>
      <Routes>
        <Route path={`/dashboard`} element={<Homepage />}></Route>
        <Route path={`/leads`} element={<LeadsData />}></Route>
        <Route path={`/users`} element={<UsersData />}></Route>
        <Route path={`/dealers`} element={<DealersData />}></Route>
        <Route path={`/staff`} element={<StaffData />}></Route>
        <Route path={`/reviews`} element={<Reviews />}></Route>
        <Route path={`/shipments`} element={<Shipments />}></Route>
        <Route path={`/users/:id/`} element={<UserDetails />}>
          {/* <Route path={`/users/:id/personalInformation`} element={<UserProfileRegularPage />}></Route> */}
          {/* <Route path={`/user-profile/:id/`} element={<UserProfileLayout/>}></Route> */}

          {/* <Route path={`/users/:id/relationshipManager`} element={<UserRelationshipManagerPage />} /> */}
          {/* <Route path={`/users/:id/approvals`} element={<UserApprovalsPage />} /> */}
          {/* <Route path={`/users/:id/documents`} element={<UserApprovalsPage />} /> */}
          {/* <Route path={`/users/:id/checks`} element={<UserApprovalsPage />} />
          <Route path={`/users/:id/settings`} element={<UserProfileSettingPage />} /> */}
        </Route>

         
        {/* <Route path={`/auctions`} element={<AllAuctions />}></Route> */}
        <Route path={`/direct-sales`} element={<DirectSaleItems />}></Route>
        <Route path={`/direct-sales/:id`} element={<DirectSaleDetails />}></Route>
        <Route path={`/my-auctions`} element={<MyAuctions />}></Route>
        <Route path={`/auction-groups`} element={<AuctionGroups />}></Route>
        <Route path={`/auction-groups/:id`} element={<AuctionGroupDetails />}></Route>
        <Route path={`/items`} element={<AllItems />}></Route>
        <Route path={`/add-item`} element={<AddItem />}></Route>
        <Route path={`/bulk-add-item`} element={<CSVUploader />}></Route>
        <Route path={`/payments`} element={<Payments />}></Route>
        <Route path={`/orders`} element={<OrdersListPage />}></Route>
        <Route path={`/orders/:orderId`} element={<OrderDetailsPage />}></Route>
        <Route path={`/settings`} element={<SettingsPage />}></Route>

        <Route path={`/nobids-auctions`} element={<NoBidsAuctions />}></Route>
        <Route
          path={`/rejected-auctions`}
          element={<RejectedAuctions />}
        ></Route>
        <Route
          path={`/seller-rejected-auctions`}
          element={<SellerRejectedAuctions />}
        ></Route>
        <Route
          path={`/confirmed-auctions`}
          element={<ConfirmedAuctions />}
        ></Route>
        <Route path={`/concierge`} element={<Concierge />}></Route>
        <Route
          path={`/auctions/:id`}
          element={<AuctionDetails />}
        ></Route>
        <Route
          path={`/auctions/:id/cardetails/:itemId`}
          element={<CarDetails />}
        ></Route>
        <Route
          path={`/items/:ItemId`}
          element={<CarDetails />}
        ></Route>
      </Routes>
    </Suspense>
  );
};
export default Pages;
