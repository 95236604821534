import React from "react";
import { Controller } from "react-hook-form";
import { RSelect } from "../Component";

export default function FormSelect({
  name,
  control,
  label,
  options,
  displayOption,
  transformer,
  ...props
}) {
  // Default display option function if not provided
  const defaultDisplayOption = (option) => ({ label: option, value: option });

  // Use provided displayOption function or fall back to default
  const getOptionDisplay = displayOption || defaultDisplayOption;

  // Default transformer function if not provided
  const defaultTransformer = (value) => value;

  // Use provided transformer function or fall back to default
  const transformValue = transformer || defaultTransformer;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div style={{ width: "100%" }}>
          <label>{label}</label>
          <RSelect
            options={options.map(getOptionDisplay)}
            value={field.value && getOptionDisplay(field.value)}
            onChange={(selectedOption) => {
              const transformedValue = transformValue(selectedOption.value);
              field.onChange(transformedValue);
            }}
            style={{ width: "100%" }}
            {...props}
          />
          {error && <div className="text-red-500 my-1">{error.message}</div>}
        </div>
      )}
    />
  );
}