// socketConfig.js
import { io } from 'socket.io-client';
import { create } from 'zustand';

class TimeSync {
  constructor() {
    this.serverTimeDiff = 0; // Difference between server and client time
    this.lastSync = 0; // Last time we synced with server
    this.syncInterval = 1000; // How often we expect server updates
    this.driftThreshold = 1000; // Maximum allowed drift before warning
    this.fallbackTimer = null;
  }

  // Update time difference when we get server time
  updateServerTime(serverTime) {
    this.serverTimeDiff = serverTime - Date.now();
    this.lastSync = Date.now();
  }

  // Get current time (with fallback)
  getCurrentTime() {
    const timeSinceLastSync = Date.now() - this.lastSync;
    
    // If we haven't synced recently, log a warning
    if (timeSinceLastSync > this.driftThreshold) {
      console.warn('Using estimated time - last server sync was', 
        Math.floor(timeSinceLastSync / 1000), 'seconds ago');
    }

    // Return estimated current server time
    return Date.now() + this.serverTimeDiff;
  }

  // Start fallback timer
  startFallbackTimer(callback) {
    if (this.fallbackTimer) return;
    
    this.fallbackTimer = setInterval(() => {
      callback(this.getCurrentTime());
    }, this.syncInterval);
  }

  // Stop fallback timer
  stopFallbackTimer() {
    if (this.fallbackTimer) {
      clearInterval(this.fallbackTimer);
      this.fallbackTimer = null;
    }
  }
}

const timeSync = new TimeSync();

const useSocketStore = create((set, get) => ({
  socket: null,
  serverTime: null,
  isConnected: false,
  hasInitialSync: false,
  setSocket: (socket) => set({ socket }),
  setServerTime: (time) => {
    timeSync.updateServerTime(time);
    set({ 
      serverTime: time,
      hasInitialSync: true 
    });
  },
  setIsConnected: (status) => set({ isConnected: status }),
  getCurrentTime: () => timeSync.getCurrentTime(),
}));

const SOCKET_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

const initializeSocket = () => {
  const { setSocket, setServerTime, setIsConnected } = useSocketStore.getState();

  // If socket already exists, return existing socket
  if (useSocketStore.getState().socket) {
    return useSocketStore.getState().socket;
  }

  const socket = io(SOCKET_URL, {
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    timeout: 20000,
    autoConnect: true,
    path:'/socket'
  });

  // Connection event handlers
  socket.on('connect', () => {
    console.log('Socket connected');
    setIsConnected(true);
    timeSync.stopFallbackTimer(); // Stop fallback when connected
  });

  socket.on('disconnect', () => {
    console.log('Socket disconnected');
    setIsConnected(false);
    
    // Start fallback timer when disconnected
    timeSync.startFallbackTimer((estimatedTime) => {
      setServerTime(estimatedTime);
    });
  });

  socket.on('connect_error', (error) => {
    console.error('Connection error:', error);
    setIsConnected(false);
  });

  // Server time sync
  socket.on('serverTime', (timestamp) => {
    setServerTime(timestamp);
  });

  // Store socket instance
  setSocket(socket);

  return socket;
};

// Hook for using socket and time in components
const useSocket = () => {
  const socket = useSocketStore((state) => state.socket);
  const serverTime = useSocketStore((state) => state.serverTime);
  const isConnected = useSocketStore((state) => state.isConnected);
  const hasInitialSync = useSocketStore((state) => state.hasInitialSync);
  const getCurrentTime = useSocketStore((state) => state.getCurrentTime);

  return { 
    socket, 
    serverTime, 
    isConnected, 
    hasInitialSync,
    getCurrentTime 
  };
};

export { initializeSocket, useSocket, useSocketStore };