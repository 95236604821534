import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  HorizontalTabs,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Button,
  Icon,
} from "../../../components/Component";
import { Box, } from "@mui/material";
import { useSelector } from "react-redux";
import { CarGeneralInfo } from "./InspectionCarGeneralTab";
import { CarHistoryInfo } from "./InspectionCarHistoryTab";
import { CarOptionsInfo } from "./InspectionCarOptions";
import { CarBodyInfo } from "./InspectionCarBodyTab";
import { CarMechanicalInfo } from "./InspectionCarMechanicalTab";
import { CarInteriorInfo } from "./InspectionCarInteriorTab";
import { Col, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { getImage } from "../../../utils/uploadImageToS3";
import InspectionForm from "./InspectionForm";
import AddAuctionModal from "./CreateAuctionModal";
import { Roles, auctionStatus, carStatus } from "../../../utils/Utils";

export default function AuctionCarDetailsInspectionTab({ isConciergeBooking }) {
  const methods = useForm();
  const { handleSubmit, reset, control, setValue } = methods;
  // const onSubmit = (data) => console.log(data);
  const carDetails = useSelector(
    (state) => state.carDetails.data,
    (olstate, newstate) => olstate.carDetails !== newstate.carDetails
  );
  const loggedInUser = useSelector((state) => state?.auth?.loggedInUser);

  const loggedInUserRoles = loggedInUser?.roles;
  let myRole = '';
  if (loggedInUserRoles.some((role) => role.name === Roles.SuperAdmin)) {
    myRole = 'SuperAdmin';
  } else if (loggedInUserRoles.some((role) => role.name === Roles.Concierge)) {
    myRole = 'Concierge';
  } else if (loggedInUserRoles.some((role) => role.name === Roles.Admin)) {
    myRole = 'Admin';
  } else if (loggedInUserRoles.some((role) => role.name === Roles.RM)) {
    myRole = 'RM';
  }

  const tabs = [
    'Car General',
    'Car History',
    'Car Options',
    'Car Body',
    'Car Mechanical',
    'Car Interior'
  ];
  const [imageSrc, setImageSrc] = useState(null);
  const [pinImages, setPinImages] = useState([]); // Track loaded images
  const [loadedImages, setLoadedImages] = useState([]); // Track loaded images
  const [create, setCreate] = useState(false); // Track loaded images

  const [open, setOpen] = useState(false); // Track loaded images
  const toggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    async function fetchImage() {
      if (carDetails && carDetails.inspection && carDetails.inspection.values) {
        const twodDiagramItem = carDetails.inspection.values.find(
          (item) => item.type === 'twodDiagram'
        );
        const diagramItem = carDetails.inspection.values.find(
          (item) => item.type === 'imageDiagram'
        );
        if (twodDiagramItem) {
          const imageUrl = twodDiagramItem?.value?.image;
          const obj = await getImage(imageUrl);
          setImageSrc(obj);
          // Fetch pin images
          const pinImages = carDetails.inspection.values
            .filter((item) => item.type === 'twodDiagram')
            .flatMap((twodDiagramItem) =>
              twodDiagramItem?.value?.pins?.map((pin) => pin.image)
            );

          // Load all pin images, including null values
          const pinImagePromises = pinImages.map((pinImage) => {
            if (pinImage !== null) {
              return getImage(pinImage);
            }
            return null; // Return null for null pin images
          });

          const pinImageUrls = await Promise.all(pinImagePromises);

          // pinImageUrls will contain null values for pins with null images
          setLoadedImages(pinImageUrls);
        }
        if (diagramItem) {
          const imageUrl = diagramItem.value?.image;
          const obj = await getImage(imageUrl);
          setImageSrc(obj);
          // Fetch pin images
          const pinImages = carDetails.inspection.values
            .filter((item) => item.type === 'imageDiagram')
            .flatMap((diagramItem) =>
              diagramItem?.value?.pins?.map((pin) => pin?.image)
            );

          // Load all pin images, including null values
          const pinImagePromises = pinImages.map((pinImage) => {
            if (pinImage !== null) {
              return getImage(pinImage);
            }
            return null; // Return null for null pin images
          });

          const pinImageUrls = await Promise.all(pinImagePromises);

          // pinImageUrls will contain null values for pins with null images
          setLoadedImages(pinImageUrls);
        }
      }
    }

    fetchImage();
  }, [carDetails]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPinIndex, setSelectedPinIndex] = useState(null);

  const handleSvgClick = (pinIndex) => {
    setSelectedPinIndex(pinIndex);
    setModalOpen(true);
  };
  const argbToRgb = (argbValue) => {
    const alpha = (argbValue >>> 24) & 0xff;
    const red = (argbValue >>> 16) & 0xff;
    const green = (argbValue >>> 8) & 0xff;
    const blue = argbValue & 0xff;

    return { red, green, blue };
  };

  return (
    <Box sx={{ width: 1, height: 1 }}>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Inspection Details</BlockTitle>
            <BlockDes>
              <p>Inspection details</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent>
            {
              carDetails.status !== carStatus.AUCTION_LIVE && (
                // <></>
                <Button
                  type="button"
                  onClick={() => setOpen(true)}
                  color="primary"
                  style={{ margin: 10 }}
                >
                  Create Auction
                </Button>
              )}
            {
              !carDetails?.inspection && (
                <Button
                  onClick={() => setCreate(true)}
                  color="primary"
                  style={{ margin: 10 }}
                >
                  Create Inspection
                </Button>
              )}
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      {carDetails?.inspection?.values ? (
        <ul className="nk-store-statistics">
          <li>
            {carDetails?.inspection?.values
              ?.filter((e) => e.type !== 'image')
              .map((c, index) => (
                <Col sm="12" key={index}>
                  <div className="info">
                    <div
                      className="card-row"
                      style={{
                        display: 'flex',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        marginBottom: '10px'
                      }}
                    >
                      <div
                        className="half-width"
                        style={{ flex: 1, padding: '10px' }}
                      >
                        <div
                          className="title"
                          style={{ fontWeight: 'bold', fontSize: '16px' }}
                        >
                          {c.type === 'twodDiagram' || c.type === 'imageDiagram'
                            ? 'Damage diagram'
                            : c?.key.charAt(0).toUpperCase() + c?.key.slice(1)}
                        </div>
                      </div>
                      <div
                        className="half-width"
                        style={{ flex: 1, padding: '10px' }}
                      >
                        {c.type === 'text' ||
                          c.type === 'dropdown' ||
                          c.type === 'number' ? (
                          <div
                            className="count-details"
                            style={{ fontSize: '14px' }}
                          >
                            {c?.value}
                          </div>
                        ) : c.type === 'radio' ? (
                          <div
                            className="count-details"
                            style={{ fontSize: '14px' }}
                          >
                            {c.value && typeof c.value === 'object'
                              ? c.value.name
                              : c.value}
                          </div>
                        ) : c.type === 'distanceField' ? (
                          <div
                            className="count-details"
                            style={{ fontSize: '14px' }}
                          >
                            {c.value && typeof c.value === 'object'
                              ? c.value.name
                              : c.value}
                          </div>
                        ) : c.type === 'date' ? (
                          <div
                            className="count-details"
                            style={{ fontSize: '14px' }}
                          >
                            {c.value ? new Date(c.value).toDateString() : ''}
                          </div>
                        ) : c.type === 'color' &&
                          c.value &&
                          typeof c.value === 'number' ? (
                          <div
                            className="count-details"
                            style={{ fontSize: '14px' }}
                          >
                            <div
                              style={{
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.4)',
                                backgroundColor: `rgb(${argbToRgb(c?.value).red
                                  }, ${argbToRgb(c?.value).green}, ${argbToRgb(c?.value).blue
                                  })`,
                                width: '20px',
                                height: '20px'
                              }}
                            ></div>
                          </div>
                        ) : c.type === 'color' &&
                          c.value &&
                          typeof c.value === 'string' ? (
                          <div
                            className="count-details"
                            style={{ fontSize: '14px' }}
                          >
                            {c?.value}
                          </div>
                        ) : c.type === 'multiLineText' ? (
                          <div
                            className="count-details"
                            style={{ fontSize: '14px' }}
                          >
                            {c.value && typeof c.value === 'object'
                              ? c.value.name
                              : c.value}
                          </div>
                        ) : c.type === 'document' ? (
                          <div
                            onClick={async (e) => {
                              e.preventDefault();
                              const imageUrl = await getImage(c.value);
                              if (imageUrl) {
                                window.open(imageUrl, '_blank');
                              }
                            }}
                            className="count-details"
                            style={{ fontSize: '14px' }}
                          >
                            {c.value && typeof c.value === 'object' ? (
                              c.value.name
                            ) : (
                              <Icon
                                name="clip"
                                style={{ cursor: 'pointer' }}
                              ></Icon>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {(c.type === 'twodDiagram' || c.type === 'imageDiagram') &&
                      imageSrc ? (
                      <div style={{ position: 'relative', width: '500px' }}>
                        <img
                          src={imageSrc}
                          alt="Diagram"
                          style={{ width: '100%' }}
                        />
                        {c.value?.pins.map((pin, pinIndex) => (
                          <div
                            key={pinIndex}
                            style={{
                              position: 'absolute',
                              left: `${pin.x * 100}%`, // Adjust left position based on x coordinate
                              top: `${pin.y * 50}%`, // Adjust top position based on y coordinate
                              transform: 'translate(-50%, -50%)' // Center the pin on the coordinates
                            }}
                          >
                            {loadedImages?.length > 0 ? (
                              <>
                                {loadedImages[pinIndex] ? (
                                  <>
                                    <div
                                      onClick={() => handleSvgClick(pinIndex)}
                                      style={{ cursor: 'pointer' }} // Add a pointer cursor to indicate clickability
                                    >
                                      <svg
                                        width="18"
                                        height="21"
                                        viewBox="0 0 18 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M9 21L12 17H6L9 21Z"
                                          fill="#E52128"
                                        />
                                        <circle
                                          cx="9"
                                          cy="9"
                                          r="9"
                                          fill="#E52128"
                                        />
                                        <path
                                          d="M10.8802 13.0288L10.7537 13.5428C10.3743 13.6917 10.0711 13.805 9.84541 13.883C9.61944 13.9611 9.35688 14 9.05773 14C8.59833 14 8.241 13.8881 7.98615 13.6659C7.73129 13.4428 7.60383 13.1601 7.60383 12.8171C7.60383 12.6843 7.61308 12.5478 7.6323 12.4086C7.6517 12.2693 7.68253 12.1124 7.72472 11.9371L8.19897 10.2683C8.24116 10.1086 8.27702 9.95715 8.30575 9.81398C8.33487 9.67169 8.34891 9.54077 8.34891 9.42293C8.34891 9.20982 8.30453 9.06076 8.21617 8.97695C8.12781 8.89338 7.95921 8.85095 7.7089 8.85095C7.5863 8.85095 7.46029 8.87047 7.33185 8.90822C7.20284 8.94614 7.09258 8.98276 7 9.0168L7.12682 8.50233C7.43749 8.37658 7.73446 8.2689 8.01852 8.17953C8.30258 8.08991 8.57099 8.04506 8.82486 8.04506C9.2811 8.04506 9.63316 8.15468 9.88038 8.37392C10.1276 8.59332 10.2512 8.87773 10.2512 9.22821C10.2512 9.30072 10.2431 9.42849 10.2256 9.61111C10.2086 9.79413 10.1769 9.96183 10.1306 10.1144L9.65839 11.7764C9.61969 11.9099 9.58488 12.0625 9.55462 12.2342C9.52338 12.4048 9.50845 12.5352 9.50845 12.6226C9.50845 12.8433 9.55794 12.994 9.65717 13.0741C9.75713 13.1542 9.92931 13.194 10.1739 13.194C10.2887 13.194 10.4193 13.1738 10.5645 13.134C10.7092 13.0942 10.8148 13.0593 10.8802 13.0288ZM11 6.05102C11 6.3406 10.8902 6.58791 10.6696 6.79126C10.4496 6.99533 10.1844 7.09745 9.87422 7.09745C9.56305 7.09745 9.29725 6.99533 9.07469 6.79126C8.85253 6.58783 8.74121 6.3406 8.74121 6.05102C8.74121 5.76201 8.85253 5.5143 9.07469 5.30837C9.29684 5.10276 9.56314 5 9.87422 5C10.1843 5 10.4496 5.10301 10.6696 5.30837C10.8904 5.5143 11 5.76209 11 6.05102Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </div>
                                  </>
                                ) : (
                                  <svg
                                    width="18"
                                    height="21"
                                    viewBox="0 0 18 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9 21L12 17H6L9 21Z"
                                      fill="#E52128"
                                    />
                                    <circle
                                      cx="9"
                                      cy="9"
                                      r="9"
                                      fill="#E52128"
                                    />
                                    <path
                                      d="M10.8802 13.0288L10.7537 13.5428C10.3743 13.6917 10.0711 13.805 9.84541 13.883C9.61944 13.9611 9.35688 14 9.05773 14C8.59833 14 8.241 13.8881 7.98615 13.6659C7.73129 13.4428 7.60383 13.1601 7.60383 12.8171C7.60383 12.6843 7.61308 12.5478 7.6323 12.4086C7.6517 12.2693 7.68253 12.1124 7.72472 11.9371L8.19897 10.2683C8.24116 10.1086 8.27702 9.95715 8.30575 9.81398C8.33487 9.67169 8.34891 9.54077 8.34891 9.42293C8.34891 9.20982 8.30453 9.06076 8.21617 8.97695C8.12781 8.89338 7.95921 8.85095 7.7089 8.85095C7.5863 8.85095 7.46029 8.87047 7.33185 8.90822C7.20284 8.94614 7.09258 8.98276 7 9.0168L7.12682 8.50233C7.43749 8.37658 7.73446 8.2689 8.01852 8.17953C8.30258 8.08991 8.57099 8.04506 8.82486 8.04506C9.2811 8.04506 9.63316 8.15468 9.88038 8.37392C10.1276 8.59332 10.2512 8.87773 10.2512 9.22821C10.2512 9.30072 10.2431 9.42849 10.2256 9.61111C10.2086 9.79413 10.1769 9.96183 10.1306 10.1144L9.65839 11.7764C9.61969 11.9099 9.58488 12.0625 9.55462 12.2342C9.52338 12.4048 9.50845 12.5352 9.50845 12.6226C9.50845 12.8433 9.55794 12.994 9.65717 13.0741C9.75713 13.1542 9.92931 13.194 10.1739 13.194C10.2887 13.194 10.4193 13.1738 10.5645 13.134C10.7092 13.0942 10.8148 13.0593 10.8802 13.0288ZM11 6.05102C11 6.3406 10.8902 6.58791 10.6696 6.79126C10.4496 6.99533 10.1844 7.09745 9.87422 7.09745C9.56305 7.09745 9.29725 6.99533 9.07469 6.79126C8.85253 6.58783 8.74121 6.3406 8.74121 6.05102C8.74121 5.76201 8.85253 5.5143 9.07469 5.30837C9.29684 5.10276 9.56314 5 9.87422 5C10.1843 5 10.4496 5.10301 10.6696 5.30837C10.8904 5.5143 11 5.76209 11 6.05102Z"
                                      fill="white"
                                    />
                                  </svg>
                                )}
                                <div>{pin.note}</div>
                              </>
                            ) : (
                              <div className="text-center">
                                <Spinner color="primary" />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </Col>
              ))}
          </li>
        </ul>
      ) : (
        // <HorizontalTabs labels={tabs}>
        //   <CarGeneralInfo />
        //   <CarHistoryInfo />
        //   <CarOptionsInfo />
        //   <CarBodyInfo />
        //   <CarMechanicalInfo />
        //   <CarInteriorInfo />
        // </HorizontalTabs>
        <>
          {!create ? (
            <div
              style={{
                display: 'flex',
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <div>No Inspection Found</div>
            </div>
          ) : (
            <InspectionForm
              vertical_id={carDetails?.vertical_id}
              baseform_id={carDetails?.id}
            />
          )}
        </>
      )}


      <Modal
        className="modal-dialog-centered"
        size="lg"
        backdrop="static"
        toggle={() => setModalOpen(!modalOpen)}
        isOpen={modalOpen}
      >
        <ModalBody>
          <a href="#cancel" className="close">
            <Icon
              name="cross-sm"
              onClick={(ev) => {
                ev.preventDefault();
                setModalOpen(!modalOpen);
              }}
            ></Icon>
          </a>
          <div className="nk-tnx-details mt-sm-3">
            <img
              src={loadedImages[selectedPinIndex]}
              style={{
                width: '100%'
              }}
              alt="Pin"
            />
          </div>
        </ModalBody>
      </Modal>
    </Box>
  );
}
