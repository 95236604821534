import React, { useEffect } from 'react'
import Content from '../../layout/content/Content'
import AddItemForm from './components/AddItemForm'
import { Block, BlockDes, BlockHead, BlockHeadContent, BlockTitle } from '../../components/Component'
import Head from '../../layout/head/Head'
import { Card } from 'reactstrap'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { verticalId } from '../../utils/configEnv'
import { useDispatch } from 'react-redux'
import { resetItemCreateState } from '../../redux/features/itemsSlice/itemsSlice'

export default function AddItem() {
    const state = useSelector((state) => state.items)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        if (state.createStatus === 'success' && state.createdId) {
            navigate(`/items/${state.createdId}`, { replace: true })
            dispatch(resetItemCreateState())
        }
    }, [state])
    return (<React.Fragment>
        <Head title={`Add Item`}></Head>
        <Content>

            <Card style={{ padding: '3rem' }}>
                <BlockHead size="lg">
                    <BlockHeadContent>
                        <BlockTitle tag="h4">Add Item</BlockTitle>
                        <BlockDes>
                            <p>Form to add item to inventory</p>
                        </BlockDes>
                    </BlockHeadContent>
                </BlockHead>
                <AddItemForm />
            </Card>
        </Content>
    </React.Fragment>
    )
}
