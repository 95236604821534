import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";

const initialState = {
  loggedInUser: {},
  isLoggedIn: false,
  loading: false,
  error: null,
};
const login = createAsyncThunk("auth/login", (data) => {
  const { email, password } = data;
  return axios
    .post(`/device/auth/login`, {
      username: email,
      password,
    })
    .then((response) => response.data.data);
});

// const refresh = createAsyncThunk("auth/refresh", () => {
//   return axios.get(`/device/api/v1/auth/refresh`).then((response) => response.data.data).catch((err) => {
//     console.log(err);
//     throw err;
//   });
// });

const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.loggedInUser = null;
    },
    refresh: (state, action) => {
      console.log(action.payload)
      state.isLoggedIn = true;
      state.loggedInUser = action.payload;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // login cases
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.loggedInUser = action.payload;
      state.isLoggedIn = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.loggedInUser = null;
      state.isLoggedIn = false;
      state.error = action.error.message;
    });
    // refresh cases
    // builder.addCase(refresh.pending, (state) => {
    //   state.loading = true;
    //   state.error = null;
    // });
    // builder.addCase(refresh.fulfilled, (state, action) => {
    //   // console.log(action.payload);
    //   state.loading = false;
    //   state.loggedInUser = action.payload;
    //   state.isLoggedIn = true;
    // });
    // builder.addCase(refresh.rejected, (state, action) => {
    //   state.loading = false;
    //   state.loggedInUser = null;
    //   state.isLoggedIn = false;
    //   state.error = action.error.message;
    // });
  },
});

const logout = userSlice.actions.logout;

export default userSlice.reducer;
export { login, logout };
