import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { debounce } from "lodash";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const initialState = {
  data: [],
  loading: false,
  error: null,
};
const addFreePackage = createAsyncThunk("freePackage", async (data) => {
  try {
    const url = `/device/api/v1/user-free-package`;
    const response = await axios.post(url, data);
    toast.success('Successfully Added');
    window.location.reload();
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});
const deleteFreePackage = createAsyncThunk("deleteFreePackage", async (data) => {
  const { user_id, v_id } = data
  try {
    const url = `/device/api/v1/user-free-package/${user_id}/${v_id}`;
    const response = await axios.delete(url);
    toast.success('Successfully deleted');
    window.location.reload();
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

const fetchPackage = createAsyncThunk("fetchPackage", (data) => {
  let url = `/device/api/v1/user-free-package/user`;
  return axios.post(url, data).then((response) => {
    return response.data;
  });
});
const updatePackage = createAsyncThunk("updatePackage", ({ data, user, vertical }) => {
  let url = `/device/api/v1/user-free-package/${user}/${vertical}`;
  return axios.put(url, data).then((response) => {
    toast.success('Successfully Updated');
    window.location.reload();
    return response.data;
  });
});

const freePackageSlice = createSlice({
  name: "freePackage",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(addFreePackage.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(addFreePackage.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      // state.data = action.payload?.data??null;
    });
    builder.addCase(addFreePackage.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(fetchPackage.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchPackage.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data ?? null;
    });
    builder.addCase(fetchPackage.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(deleteFreePackage.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(deleteFreePackage.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data ?? null;
    });
    builder.addCase(deleteFreePackage.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(updatePackage.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(updatePackage.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;

    });
    builder.addCase(updatePackage.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default freePackageSlice.reducer;
// export const { setSelected, setAllSelected } = freePackageSlice.actions;
export { addFreePackage, fetchPackage, deleteFreePackage, updatePackage };
