import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { setReserveMet } from '../../../redux/features/auctionSlice/reserveMetSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const Circle = ({ auction }) => {

    const dispatch = useDispatch()


    const has_reserve_met = useSelector((state)=> state.auctionGroupDetails.data?.current_auction?.has_reserve_met)
    // const [met, setMet] = useState(false)
    const handleClick = (met) => {
        try {
           dispatch( setReserveMet({id:auction.id,body:{has_reserve_met:met}}))
        } catch (e) { 
            console.log(e);
            
        }
    }

    return (
        <Stack direction='row' spacing={1} alignItems='center'>
            <Box
                onClick={() => handleClick(false)}
                sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    border: `2px solid red`,
                    backgroundColor: !has_reserve_met ? 'red' : 'transparent',
                    cursor: 'pointer',
                }}
            />
            <Box
                onClick={() => handleClick(true)}
                sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    border: `2px solid green`,
                    backgroundColor: has_reserve_met ? 'green' : 'transparent',
                    cursor: 'pointer',
                }}
            />
        </Stack>
    );
};

export default Circle;
