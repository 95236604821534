import React, { useEffect, useState } from 'react'
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import {  Icon, } from '../../../components/Component'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { Button } from '@mui/material'
import { softDeleteBulk } from '../../../redux/features/itemsSlice/itemsSlice'




export default function DeleteItemsModalButton({selected}) {

    const [loading, setLoading] = useState(false)



    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };


    const methods = useForm({});
    const { handleSubmit  } = methods;

    const dispatch = useDispatch();

    const handleBulkDelete = async (data) => {
        setLoading(true)
        try {
            await dispatch(softDeleteBulk({ids:selected}))?.unwrap();
            toast.success('Items deleted successfully');
            toggle();
        } catch (error) {
            toast.error(error.message);
        }finally{
            setLoading(false)
        }
        
    };

    return (
        <div>
            <div className="me-3">
                <Button variant='contained' color='error' onClick={toggle}>
                    Delete
                </Button>
            </div>
            <Modal isOpen={isOpen}>
                <form onSubmit={handleSubmit(handleBulkDelete)}>
                    <ModalHeader
                        close={
                            <button className="close" onClick={toggle} type="button">
                                <Icon name="cross" />
                            </button>
                        }
                    >
                        Confirm Delete
                    </ModalHeader>
                    <ModalBody>
                        Please confirm that you want to delete the selected items.
                    </ModalBody>
                    <ModalFooter>
                        <Row>
                            <Col>
                                <Button color="light" onClick={toggle} style={{ width: "100%" }}>
                                    Cancel
                                </Button>
                            </Col>
                            <Col>
                                <Button color="primary" onClick={handleSubmit(handleBulkDelete)} style={{ width: "100%" }}>
                                    {loading ? <Spinner size="sm" /> : "Confirm"}
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </form>
            </Modal>
        </div >
    )
}
