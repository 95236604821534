import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '../../../utils/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uuid from 'react-uuid';
import { uploadToS3 } from '../../../utils/uploadImageToS3';

const initialState = {
  data: {},
  loading: false,
  error: null,
  listAsDirectSaleStatus: 'init',
  listAsDirectSaleError: null,
  editItemStatus: 'init',
  editItemError:null,
};
const fetchCarDetails = createAsyncThunk('cardetails', (id) => {
  // console.log(id);
  let url = `/device/api/v1/baseformvalues/${id}`;
  return axios.get(url).then((response) => {
    return response.data;
  });
});
const updateItem = createAsyncThunk('updateItem', async (payload ) => {
  try {
    const {data,id} = payload
    let imagePaths = data.images || [];

    // Separate existing image URLs from new File objects
    const images = imagePaths.filter((img) =>  typeof img==="string") ?? [];
    const newImages = imagePaths.filter((img) => img instanceof File); // File objects

    // Upload only new images
    if (newImages.length > 0) {
      for (let i = 0; i < newImages.length; i++) {
        const fileType = newImages[i].name.split('.').pop();
        const filePath = `item/images/${uuid()}.${fileType}`;
        await uploadToS3(newImages[i], filePath, newImages[i].type);
        images.push(filePath); // Add uploaded image paths to the final array
      }
    }

    // Set the images field to the array containing both existing and new image paths
    data.images = images;
console.log('------------------------------------------')
    console.log(data)
    console.log('------------------------------------------')
    let url = `/device/api/v1/baseformvalues/update/${id}`;
    const response = await axios.put(url, data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});
const addInspection = createAsyncThunk('addInspection', async (data) => {
  try {
    let url = `/device/api/v1/inspectionformvalues/create`;
    const response = await axios.post(url, data);
    toast.success(' Added');
    window.location.reload();
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

const listAsDirectSale = createAsyncThunk('listAsDirectSale', async (data) => {
  let url = `/device/api/v1/direct-sale/move/${data?.id}`;
  const response = await axios.post(url);
  return response.data;
});



const carDetails = createSlice({
  name: 'carDetails',
  initialState,
  reducers: {
    updateCarInspection: (state, action) => {
      const data = state.data;
      data.carInspection = action.payload;
      state.data = data;
    },
    setCar: (state, action) => {
      state.data = action.payload;
    },
    resetItemEditState: (state) => {
      state.editItemStatus = 'init';
      state.editItemError = null
    },
    resetListAsDirectSale: (state) => {
      state.listAsDirectSaleStatus = 'init';
      state.listAsDirectSaleError = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCarDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = {};
    });
    builder.addCase(fetchCarDetails.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(fetchCarDetails.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = action.error.message;
    });
    builder.addCase(updateItem.pending, (state) => {
      state.editItemStatus = 'loading';
      state.listAsDirectSaleError = null;
    });
    builder.addCase(updateItem.fulfilled, (state, action) => {
      state.editItemStatus = 'success';
      state.listAsDirectSaleError = null;
      state.data = action.payload?.data;
    });
    builder.addCase(updateItem.rejected, (state, action) => {
      state.editItemStatus = 'error';
      state.listAsDirectSaleError =  action.error.message;
    });
    builder.addCase(listAsDirectSale.pending, (state) => {
      state.listAsDirectSaleStatus = 'loading';
      state.listAsDirectSaleError = null;
    });
    builder.addCase(listAsDirectSale.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.listAsDirectSaleStatus = 'success';
    });
    builder.addCase(listAsDirectSale.rejected, (state, action) => {
      state.listAsDirectSaleStatus = 'error';
      state.listAsDirectSaleError = action.error.message;
    });
   
  }
});

export default carDetails.reducer;
export const { updateCarInspection, setCar ,resetItemEditState,resetListAsDirectSale} = carDetails.actions;
export { fetchCarDetails, addInspection, updateItem, listAsDirectSale };
