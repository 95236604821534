import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '../../../utils/axios';
import { debounce } from 'lodash';
import uuid from 'react-uuid';
import { uploadToS3 } from '../../../utils/uploadImageToS3';

const initialState = {
  data: [],
  totalCount: 0,
  perPage: 10,
  loading: false,
  error: null,
  createStatus: 'init',
  createLoading: false,
  createdId: null,
  selected: [],
  createBulkAuctionStatus: 'init',
  createBulkAuctionError: null,
  createBulkDirectSaleStatus: 'init',
  createBulkDirectSaleError: null
};
const addItem = createAsyncThunk('addItem', async (data) => {
  const images = data.images;
  if (images) {
    let imagePaths = [];
    for (let i = 0; i < images.length; i++) {
      const fileType = images[i].name.split('.').pop();
      const filePath = `item/images/${uuid()}.${fileType}`;
      await uploadToS3(images[i], filePath, images[i].type);
      imagePaths.push(filePath);
    }
    data.images = imagePaths;
  }

  let url = `/device/api/v1/baseformvalues/create`;
  const response = await axios.post(url, data);
  return response.data;
});
const fetchCars = createAsyncThunk('items', (data) => {
  let url = `/device/api/v1/cars/user/list`;
  return axios.get(url,{params:data}).then((response) => {
    return response.data['data'];
  });
});
const createBulkAuctions = createAsyncThunk(
  'createBulkAuctions',
  (data, { getState }) => {
    let items = getState().items.selected;
    if (!items?.length) {
      throw Error('No Items selected');
    }
    data.items = items;
    console.log(data);
    let url = `/device/api/v1/create-group`;
    return axios.post(url, data).then((response) => {
      return response.data['data'];
    });
  }
);
const createBulkDirectSale = createAsyncThunk(
  'createBulkDirectSale',
  (data, { getState }) => {
    let items = getState().items.selected;
    if (!items?.length) {
      throw Error('No Items selected');
    }
    data.items = items;
    let url = '/device/api/v1/direct-sale/bulk-move';
    return axios.post(url, data).then((response) => {
      return response.data['data'];
    });
  }
);
export const softDeleteBulk = createAsyncThunk('softDeleteBulk', async (data) => {
  let url = `/device/api/v1/baseformvalues/softDeleteMany`;
  const response = await axios.put(url,data);
  return data.ids;
});
const clearItems = createAsyncThunk('clearItems', async () => {
  return null;
});
const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    setSelected: (state, action) => {
      const { id, value } = action.payload;
      if (value) {
        state.selected.push(id);
      } else {
        state.selected = state.selected.filter((v) => v !== id);
      }
    },

    resetItemCreateState: (state) => {
      state.createdId = null;
      state.createStatus = 'init';
    },
    resetBulkAuctionCreateState: (state) => {
      state.createBulkAuctionError = null;
      state.createBulkAuctionStatus = 'init';
    },
    resetBulkDirectSaleCreateState: (state) => {
      state.createBulkDirectSaleError = null;
      state.createBulkDirectSaleStatus = 'init';
    },
    setAllSelected: (state, action) => {
      const { value } = action.payload;
      if (value) {
        state.selected = state.data?.map((e) => e.id);
      } else {
        state.selected = [];
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCars.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchCars.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data;
      state.totalCount = action.payload?.paginator?.itemCount;
      state.perPage = action.payload?.paginator?.perPage;
    });
    builder.addCase(fetchCars.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(addItem.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.createStatus = 'loading';
      state.createLoading = true;
      state.createdId = null;
    });
    builder.addCase(addItem.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.createStatus = 'success';
      state.createLoading = false;
      state.createdId = action.payload?.data?.id;
    });
    builder.addCase(addItem.rejected, (state, action) => {
      state.loading = false;
      state.createStatus = 'error';
      state.createLoading = false;
    });
    // Bulk create auctions
    builder.addCase(createBulkAuctions.pending, (state) => {
      state.createBulkAuctionStatus = 'loading';
    });
    builder.addCase(createBulkAuctions.fulfilled, (state, action) => {
      state.createBulkAuctionStatus = 'success';
      state.items = state.data
        .filter((e) => state.selected.includes(e.id))
        .map((e) => (e.status = 'AUCTION_SCHEDULED'));
      state.selected = [];
    });
    builder.addCase(createBulkAuctions.rejected, (state, action) => {
      state.createBulkAuctionStatus = 'error';
      state.createBulkAuctionError = action.error?.message;
    });
    //
    builder.addCase(clearItems.fulfilled, (state) => {
      state.data = {};
    });
    // Bulk Direct Sale
    builder.addCase(createBulkDirectSale.pending, (state) => {
      state.createBulkDirectSaleStatus = 'loading';
      state.createBulkDirectSaleError = null;
    });
    builder.addCase(createBulkDirectSale.fulfilled, (state, action) => {
      state.createBulkDirectSaleStatus = 'success';
      state.items = state.data
        .filter((e) => state.selected.includes(e.id))
        .map((e) => (e.status = 'LISTED_IN_DIRECT_SALE'));
      state.selected = [];
    });
    builder.addCase(createBulkDirectSale.rejected, (state, action) => {
      state.createBulkDirectSaleStatus = 'error';
      state.createBulkDirectSaleError = action.payload?.message;
    });
    builder.addCase(softDeleteBulk.fulfilled, (state, action) => {
      state.data  = state.data.filter((e) => !action.payload.includes(e.id));
    });
  }
});

const debounced = debounce(
  ({ ...arg }, dispatch) => dispatch(fetchCars({ ...arg })),
  500
);
const debouncedFetchCars =
  ({ ...args }) =>
  (dispatch) =>
    debounced({ ...args }, dispatch);

export default itemsSlice.reducer;
export const {
  setSelected,
  setAllSelected,
  resetItemCreateState,
  resetBulkAuctionCreateState,
  resetBulkDirectSaleCreateState
} = itemsSlice.actions;
export {
  fetchCars,
  debouncedFetchCars,
  clearItems,
  addItem,
  createBulkAuctions,
  createBulkDirectSale
};
