import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { debounce } from "lodash";

const initialState = {
    data: [],
    totalCount: 0,
    perPage: 10,
    loading: false,
    error: null,
};
const fetchAuctionGroups = createAsyncThunk("auction-groups", (data) => {
    let url = `/device/api/v1/auction-group/list`;

    return axios.post(url, data).then((response) => {
        return response.data;
    });
});
const clearAuctions = createAsyncThunk('clearAuctions', async () => {
    return null;
});
const auctionGroupsSlice = createSlice({
    name: "auctionGroups",
    initialState,
    reducers: {
        setSelected: (state, action) => {
            const { index, value } = action.payload;
            // console.log(index);
            let data = state.data;
            data[index].check = value;
            state.data = data;
        },
        setAllSelected: (state, action) => {
            const { value } = action.payload;
            // console.log(value);
            let data = state.data;
            state.data = data.map((e) => {
                e.check = value;
                return e;
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAuctionGroups.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.data = [];
        });
        builder.addCase(fetchAuctionGroups.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload?.data?.data;
            state.totalCount = action.payload?.data?.paginator?.itemCount;
            state.perPage = action.payload?.data?.paginator?.perPage;
        });
        builder.addCase(fetchAuctionGroups.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
        builder.addCase(clearAuctions.fulfilled, (state) => {
            state.data = {};
        });
    },
});

const debounced = debounce(({ ...arg }, dispatch) => dispatch(fetchAuctionGroups({ ...arg })), 500);
const debouncedFetchAuctionGroups =
    ({ ...args }) =>
        (dispatch) =>
            debounced({ ...args }, dispatch);

export default auctionGroupsSlice.reducer;
export const { setSelected, setAllSelected } = auctionGroupsSlice.actions;
export { fetchAuctionGroups, debouncedFetchAuctionGroups, clearAuctions };
