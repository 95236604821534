import { Box, Divider, ListItemText, Typography, List, ListItem, Stack } from '@mui/material';
import { doc, onSnapshot } from 'firebase/firestore';
import React, { useEffect } from 'react';
import { verticalId } from '../../../utils/configEnv';
import { db } from '../../../utils/firebase';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { disconnectFromFirestore, fetchAuctionBids } from '../../../redux/features/auctionSlice/auctionGroupDetailsSlice';

export default function BidItem({ id ,auctionGroupId}) {
    const { bids } = useSelector((state) => state.auctionGroupDetails);
    const { loggedInUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!id) return;

        dispatch(fetchAuctionBids({
            auctionGroupId:auctionGroupId,
            query: { auction_id: id },
            options: {
                sort: { createdAt: -1 },
                page: 1,
                limit: 15,
            }
        }));
        return () => {
            dispatch(disconnectFromFirestore());
        };
    }, [id, dispatch]);

    moment.updateLocale('en', {
        relativeTime: {
            future: 'in %s',
            past: '%s ago',
            s: 'a few secs',
            ss: '%d secs',
            m: 'a min',
            mm: '%d mins',
            h: 'an hr',
            hh: '%d hrs',
            d: 'a day',
            dd: '%d days',
            M: 'a month',
            MM: '%d months',
            y: 'a year',
            yy: '%d years'
        }
    });

    return (
        <Box>
            <Typography variant="h6" fontWeight='bold' component="div">
                Bids
            </Typography>
            <List
                sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                    maxHeight: '420px', // Set a fixed height for the list
                    overflowY: 'auto',  // Enable vertical scrolling
                    borderRadius: 3,    // Optional: to match the look of list items
                }}
            >
                {bids.map((bid, index) => (
                    <React.Fragment key={index}>
                        <ListItem alignItems="flex-start" sx={{ borderRadius: 3, backgroundColor: '#ECECEC', marginBottom: 1 }}>
                            <ListItemText
                                primary={
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Stack direction='row' alignItems='baseline' spacing={1}>
                                            <Typography fontSize={14} variant="body1" component="span" fontWeight='bold'>
                                                {bid.user_id === loggedInUser.id ? 'Auction Houser Bidder' : `RAVO User ${bid.bidder_id}`}
                                            </Typography>
                                            <Typography fontSize={10} variant="body2" color="textSecondary">
                                                {moment(bid.time).fromNow()}
                                            </Typography>
                                        </Stack>
                                        <Typography variant="body1" component="span" fontWeight='bold' color='primary'>
                                            {bid.amount}
                                        </Typography>
                                    </Box>
                                }
                            />
                        </ListItem>
                        {index < bids.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );
}
