import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid2,
  Stack,
  Typography,
  Paper
} from '@mui/material';
import { Image } from '../../../components/Component';
import FormSingleImageUpload from '../../../components/form/FormSingleImageUpload';
import { ImageOff } from 'lucide-react';

const ImagePlaceholder = ({ message }) => (
  <Paper 
    elevation={0}
    sx={{
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'grey.100',
      border: '1px dashed',
      borderColor: 'grey.300',
      borderRadius: 1,
      p: 2
    }}
  >
    <ImageOff size={24} style={{ color: '#9e9e9e', marginBottom: 8 }} />
    <Typography variant="body2" color="text.secondary" align="center">
      {message}
    </Typography>
  </Paper>
);

export default function DealerImagesForm({
  dealer,
  onSubmit,
  toggleEdit,
  editMode
}) {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      logo: dealer?.logo,
      hero_image: dealer?.hero_image
    }
  });

  const handleFormSubmit = (data) => {
    if (editMode) {
      onSubmit(data);
      toggleEdit('images');
    }
  };

  return (
    <Card sx={{ mb: 2 }}>
      <Stack
        justifyContent="space-between"
        direction="row"
        alignItems="center"
      >
        <CardHeader 
          title="Images"
          sx={{ pb: 2 }}
        />
        <Box sx={{ pr: 2 }}>
          {editMode ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSubmit(handleFormSubmit)}
            >
              Save Changes
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => toggleEdit('images')}
            >
              Edit
            </Button>
          )}
        </Box>
      </Stack>

      <CardContent sx={{ pt: 3 }}>
        <Grid2 container spacing={4}>
          {/* Logo Section */}
          <Grid2 item size={6}>
            <Box>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  mb: 2, 
                  fontWeight: 600,
                  color: 'text.primary' 
                }}
              >
                Logo
              </Typography>
              <Box 
                sx={{ 
                  width: '100%',
                  height: 200,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                  overflow: 'hidden'
                }}
              >
                {editMode ? (
                  <FormSingleImageUpload
                    name="logo"
                    label="Logo"
                    control={control}
                  />
                ) : (
                  dealer?.logo ? (
                    <Box 
                      sx={{ 
                        width: 200,
                        height: 200,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'black'
                      }}
                    >
                      <Image 
                        image={dealer.logo}
                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                      />
                    </Box>
                  ) : (
                    <ImagePlaceholder message="No logo uploaded" />
                  )
                )}
              </Box>
            </Box>
          </Grid2>

          {/* Hero Image Section */}
          <Grid2 item size={6}>
            <Box>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  mb: 2, 
                  fontWeight: 600,
                  color: 'text.primary'
                }}
              >
                Hero Section Image
              </Typography>
              <Box 
                sx={{ 
                  width: '100%',
                  height: 200,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                  overflow: 'hidden'
                }}
              >
                {editMode ? (
                  <FormSingleImageUpload
                    name="hero_image"
                    label="Hero Section Image"
                    control={control}
                  />
                ) : (
                  dealer?.hero_image ? (
                    <Box 
                      sx={{ 
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Image 
                        image={dealer.hero_image}
                        style={{ 
                          width: '100%', 
                          height: '100%', 
                          objectFit: 'cover' 
                        }}
                      />
                    </Box>
                  ) : (
                    <ImagePlaceholder message="No hero image uploaded" />
                  )
                )}
              </Box>
            </Box>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
}