import React from 'react';
import { Controller } from 'react-hook-form';
import PriceInput from '../../pages/verticals/components/PriceInput';

export default function FormPriceInput({ control, name, label, validate, props }) {
    return (
        <Controller
            name={name}
            control={control}
            rules={{
                required: 'This field is required',
                validate: (value) => {
                    if (isNaN(Number(value))) {
                        return 'Must be a number';
                    }
                    if (validate) {
                        return validate(value)
                    }
                    return true;
                },
            }}
            render={({ field, fieldState: { error } }) => (
                <div>
                    <label>{label}</label>
                    <PriceInput
                        {...props}
                        {...field}
                        label="AED"
                        type="text"
                        onChange={(e) => {
                            const newValue = e.target.value;
                            field.onChange(newValue);
                        }}
                    />
                    {error && <p style={{ color: 'red' }}>{error.message}</p>}
                </div>
            )}
        />
    );
};