import Axios from 'axios';
import { apiUrl } from './configEnv';
import { store } from '../redux/store';
import { useDispatch } from 'react-redux';
import usersSlice from '../redux/features/usersSlice/usersSlice';

// console.log(apiUrl);
const axios = Axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

axios.interceptors.request.use((config) => {
  // Adding token before sending if logged in
  const user = store.getState().auth.loggedInUser;
  if (user) {
    config.headers['Authorization'] = 'Bearer ' + user.token;
  } else {
    config.headers['Authorization'] = null;
  }
  return config;
});

axios.interceptors.response.use(
  (res) => res,
  async (err) => {
    const prevReq = err?.config;

    // if (err?.response?.status === 401 && !prevReq.sent) {
    //   prevReq.sent = true;
    //   console.log("sending req");

    //   // const { data } = await axios.get(`/device/api/v1/auth/refresh-token`);

    //   const newAcessToken = data?.token;

    //   // prevReq.headers["Authorization"] = `Bearer ${newAcessToken}`;
    //   // useDispatch()(usersSlice.actions.refresh(data));

    //   return axios(prevReq);
    // }
    if (err.response) {
      const errorMessage = err.response.data?.message || 'An error occurred';
      return Promise.reject(new Error(errorMessage));
    }
    return Promise.reject(err);
  }
);

export { axios };
