import { Grid2, Typography } from "@mui/material";


export default function CarDetails({data}) {
  
  
  return (
    <Grid2 container spacing={2}>
    <Grid2 item size={6}>
      <Typography variant="subtitle2">Make</Typography>
      <Typography variant="body1">{data?.make}</Typography>
    </Grid2>

    <Grid2 item size={6}>
      <Typography variant="subtitle2">Model</Typography>
      <Typography variant="body1">{data?.model}</Typography>
    </Grid2>

    <Grid2 item size={6}>
      <Typography variant="subtitle2">Year</Typography>
      <Typography variant="body1">{data?.year}</Typography>
    </Grid2>

    <Grid2 item size={6}>
      <Typography variant="subtitle2">Mileage</Typography>
      <Typography variant="body1">{data?.mileage}</Typography>
    </Grid2>

    <Grid2 item size={6}>
      <Typography variant="subtitle2">VIN</Typography>
      <Typography variant="body1">{data?.vin}</Typography>
    </Grid2>

    <Grid2 item size={6}>
      <Typography variant="subtitle2">Engine</Typography>
      <Typography variant="body1">{data?.engine}</Typography>
    </Grid2>

    <Grid2 item size={6}>
      <Typography variant="subtitle2">Cylinders</Typography>
      <Typography variant="body1">{data?.cylinder}</Typography>
    </Grid2>

    <Grid2 item size={6}>
      <Typography variant="subtitle2">Fuel</Typography>
      <Typography variant="body1">{data?.fuel}</Typography>
    </Grid2>

    <Grid2 item size={6}>
      <Typography variant="subtitle2">Transmission (Gear)</Typography>
      <Typography variant="body1">{data?.transmission}</Typography>
    </Grid2>

    <Grid2 item size={6}>
      <Typography variant="subtitle2">Drive Train</Typography>
      <Typography variant="body1">{data?.drive_train}</Typography>
    </Grid2>

    <Grid2 item size={6}>
      <Typography variant="subtitle2">Type</Typography>
      <Typography variant="body1">{data?.type}</Typography>
    </Grid2>

    <Grid2 item size={6}>
      <Typography variant="subtitle2">Insurance</Typography>
      <Typography variant="body1">{data?.insurance}</Typography>
    </Grid2>

    <Grid2 item size={6}>
      <Typography variant="subtitle2">Specs</Typography>
      <Typography variant="body1">{data?.specs}</Typography>
    </Grid2>

    <Grid2 item size={6}>
      <Typography variant="subtitle2">Tags</Typography>
      <Typography variant="body1">{data?.tag && data?.tag.join(', ')}</Typography>
    </Grid2>

    <Grid2 item size={6}>
      <Typography variant="subtitle2">Auction Starting Price</Typography>
      <Typography variant="body1">{data?.auction_starting_price}</Typography>
    </Grid2>

    <Grid2 item size={6}>
      <Typography variant="subtitle2">Direct Sale Price</Typography>
      <Typography variant="body1">{data?.direct_sale_price}</Typography>
    </Grid2>

    
  </Grid2>
  );
}
