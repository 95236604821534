import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";

const initialState = {
  data: [],
  totalCount: 0,
  loading: false,
  error: null,
};
const fetchTopBiddedAuctions = createAsyncThunk("auctions/topBiddedAuctions", (data) => {
  let url = `/v1/device/api/v1/auctions/topBiddedAuctions?period=${data}`;
  // console.log(url);
  return axios.get(url).then((response) => {
    return response.data;
  });
});

const topBiddedAuctionsSlice = createSlice({
  name: "topBiddedAuctions",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchTopBiddedAuctions.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchTopBiddedAuctions.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload.data;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(fetchTopBiddedAuctions.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default topBiddedAuctionsSlice.reducer;
export { fetchTopBiddedAuctions };
