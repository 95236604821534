import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { debounce } from "lodash";

const initialState = {
  data: [],
  totalCount: 0,
  perPage: 10,
  loading: false,
  error: null,
};
const fetchReviews = createAsyncThunk("reviews", (data) => {

  let url = `/device/api/v1/reviews/`;
  return axios.post(url, data).then((response) => {
    return response.data;
  });
});
const fetchUserReviews = createAsyncThunk("userReviews", (id) => {

  let url = `/device/api/v1/reviews/user/${id}`;
  return axios.get(url).then((response) => {
    return response.data;
  });
});
const reviewSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    // setSelected: (state, action) => {
    //   const { index, value } = action.payload;
    //   // console.log(index);
    //   let data = state.data;
    //   data[index].check = value;
    //   state.data = data;
    // },
    // setAllSelected: (state, action) => {
    //   const { value } = action.payload;
    //   // console.log(value);
    //   let data = state.data;
    //   state.data = data.map((e) => {
    //     e.check = value;
    //     return e;
    //   });
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReviews.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchReviews.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data ?? null;
      state.totalCount = action.payload?.data?.paginator?.itemCount;
      state.perPage = action.payload?.data?.paginator?.perPage;
    });
    builder.addCase(fetchReviews.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });

    builder.addCase(fetchUserReviews.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchUserReviews.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data ?? null;
      state.totalCount = action.payload?.data?.paginator?.itemCount;
      state.perPage = action.payload?.data?.paginator?.perPage;
    });
    builder.addCase(fetchUserReviews.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export default reviewSlice.reducer;
// export const { setSelected, setAllSelected } = rolesSlice.actions;
export { fetchReviews, fetchUserReviews };
