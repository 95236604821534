import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { s3Url } from '../../../utils/configEnv';
import { Image } from '../../../components/Component';

const ImageCarousel = ({images}) => {

    // Configuration for the slider
    const settings = {
        dots: true, // Show navigation dots
        infinite: true, // Loop through slides
        speed: 500, // Transition speed in milliseconds
        slidesToShow: 1, // How many slides to show at once
        slidesToScroll: 1, // How many slides to scroll at once
        autoplay: true, // Enable auto-slide
        autoplaySpeed: 3000, // Auto-slide every 3 seconds
    };

    return (
        <div style={{ width: "100%", margin: "auto",alignItems:'center' }}>
            <Slider {...settings}>
                {/* Add image slides here */}
                {images?.length > 0 && images.map((image)=>
                <div key={image}>
                    <Image
                        image={image} // Replace with your image URL
                        alt="Car"
                        width='100%'
                        size='auto'
                    />
                </div>)}
                {/* Add more images if necessary */}
            </Slider>
         </div>
    );
};

export default ImageCarousel;
