import React from "react";
import { Controller } from "react-hook-form";

export default function FormTextField({ control, name, label,capitalise,...props }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div style={{ width: "100%" }}>
          <label>
            {label} {props?.required && <span className="required-label">*</span>}
          </label>
          <input
            {...field}
            onChange={(e)=>field.onChange(capitalise?e.target.value.toUpperCase():e.target.value)}
            {...props}
            className={`form-control ${error ? 'input-error' : ''}`}
            maxLength={props.maxLength || 255}
            style={{ width: "100%" }}
          />
          {error && <div className="text-red-500 my-1">{error.message}</div>}
        </div>
      )}
    />
  );
}
