import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { debounce } from "lodash";

const initialState = {
  data: null,
  status: "init",
  error: null,
};
const fetchVerticalDetails = createAsyncThunk("fetchVerticalDetails", (data) => {
  let url = `/device/api/v1/vertical/${data}`;
  return axios.get(url).then((response) => {
    return response.data;
  });
});

const fetchVerticalDetailsSlice = createSlice({
  name: "fetchVerticalDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchVerticalDetails.pending, (state) => {
      state.status = "loading";
      state.error = null;
      state.data = null;
    });
    builder.addCase(fetchVerticalDetails.fulfilled, (state, action) => {
      // console.log(action.payload.data);
      state.status = "success";
      state.data = action.payload.data;
    });
    builder.addCase(fetchVerticalDetails.rejected, (state, action) => {
      state.status = "error";
      state.data = null;
      state.error = action.error.message;
    });
  },
});

export default fetchVerticalDetailsSlice.reducer;
export { fetchVerticalDetails };
