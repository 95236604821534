import React, { useContext } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { nextLiveAuction } from '../../../redux/features/auctionSlice/auctionGroupDetailsSlice';
import SelectWinnerModalButton from './SelectWinnerModalButton';


function BottomStickyBar() {
    const dispatch = useDispatch();
    const { data } = useSelector((state)=>state.auctionGroupDetails)
    const details = data?.current_auction


    const onSkipAndContinue = () => {
        dispatch(nextLiveAuction(details?.id))
    }
    return (

        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 150,
                width: '100%',
                bgcolor: 'white',
                color: 'white',
                p: 2,
                textAlign: 'center',
                boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
            }}
        ><>
                <Stack direction='row' spacing={2} justifyContent='center'>
                    <SelectWinnerModalButton  />
                    <Button variant="contained" onClick={onSkipAndContinue} sx={{
                        backgroundColor: 'primary', // Blue background color
                        color: 'white',              // White text color
                        fontSize: '16px',            // Slightly larger font size
                        padding: '10px 40px',        // Padding for larger button
                        borderRadius: '25px',        // Rounded corners
                        textTransform: 'none',       // Disable uppercase text
                        boxShadow: '0px 4px 6px rgba(0, 123, 255, 0.1)', // Subtle shadow
                        '&:hover': {
                            backgroundColor: '#0056b3', // Darker blue on hover
                            boxShadow: '0px 6px 8px rgba(0, 123, 255, 0.3)', // Slightly stronger shadow on hover
                        },
                    }}>Skip And Continue</Button>
                </Stack>
            </>
        </Box >

    );
}

export default BottomStickyBar;