import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { debounce } from "lodash";

const initialState = {
  data: [],
  totalCount: 0,
  perPage: 10,
  loading: false,
  error: null,
  selected: [],
  cancelAuctionsStatus:'init',
  cancelAuctionsError:null
};
const fetchAuctions = createAsyncThunk("auctions", (data) => {
  let url = `/device/api/v1/auction/list`;
  return axios.post(url, data).then((response) => {
    console.log(response.data)
    return response.data;
  });
});
const clearAuctions = createAsyncThunk('clearAuctions', async () => {
  return null;
});

export const cancelSelectedAuctions = createAsyncThunk(
  "auctions/cancelSelected",
  async (_, { getState }) => {
    const state = getState();
    const selectedIds = state.auctions.selected;
    
    // Make API call to cancel all selected auctions
    const url = `/device/api/v1/auction/cancel-multiple`;
    const response = await axios.post(url, { ids: selectedIds });
    return response.data;
  }
);


const auctionsSlice = createSlice({
  name: "auctions",
  initialState,
  reducers: {
    setSelected: (state, action) => {
      const { id, value } = action.payload;
      // console.log(value)
      if (value) {
        state.selected.push(id);
      } else {
        state.selected = state.selected.filter((v) => v !== id);
      }
    },
    setAllSelected: (state, action) => {
      const { value } = action.payload;
      if (value) {
        state.selected = state.data?.map((e) => e.id);
      } else {
        state.selected = [];
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAuctions.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchAuctions.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data?.data;
      state.totalCount = action.payload?.data?.paginator?.itemCount;
      state.perPage = action.payload?.data?.paginator?.perPage;
    });
    builder.addCase(fetchAuctions.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(clearAuctions.fulfilled, (state) => {
      state.data = {};
    });
    builder.addCase(cancelSelectedAuctions.pending, (state) => {
      state.cancelAuctionsStatus = "loading"
      state.cancelAuctionsError = null;
    });
    builder.addCase(cancelSelectedAuctions.fulfilled, (state) => {
      state.cancelAuctionsStatus = "success"
      state.selected = []; // Clear selected after successful cancellation
    });
    builder.addCase(cancelSelectedAuctions.rejected, (state, action) => {
      state.cancelAuctionsStatus = "error";
      state.cancelAuctionsError = action.error.message;
    });
  },
});

const debounced = debounce(({ ...arg }, dispatch) => dispatch(fetchAuctions({ ...arg })), 500);
const debouncedFetchAuctions =
  ({ ...args }) =>
    (dispatch) =>
      debounced({ ...args }, dispatch);

export default auctionsSlice.reducer;
export const { setSelected, setAllSelected } = auctionsSlice.actions;
export { fetchAuctions, debouncedFetchAuctions, clearAuctions };
