import React, { useEffect } from "react";
import Content from "../../layout/content/Content";
import { Card, DropdownItem, DropdownMenu, DropdownToggle, Modal, UncontrolledDropdown } from "reactstrap";
import { Icon, UserAvatar } from "../../components/Component";
import { useState } from "react";
import { USER_TYPES, UserRegisterTypes, findUpper } from "../../utils/Utils";
import UserProfileRegularPage from "./components/UserProfileRegularPage";
import UserRelationshipManagerPage from "./components/UserRelationshipManagerPage";
import UserApprovalsPage from "./components/UserApprovalsPage";
import UserProfileSettingPage from "./components/UserProfileSettingPage";
import { useDispatch } from "react-redux";
import { clearUserDetails, fetchUserDetails } from "../../redux/features/usersSlice/userDetails";
import { Link, Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import UserDocumentsPage from "./components/UserDocumentsPage";
import BackButton from "../../components/button/BackButton";
import UserSubscription from "./components/UserSubscription";

import { EditUserProfileImage } from "./components/UserUpdationPopups";
import UserHistoryPage from "./components/UserHistoryPage";
import { startCase } from "lodash";
import Reviews from "./Reviews";


export default function UserDetails() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [ updateProfileImageModal, setUpdateProfileImageModal ] = useState( false );
  const dispatch = useDispatch();
  const { id, tab } = useParams();
  useEffect(() => {
    return () => {
      dispatch(clearUserDetails());
    };
  }, [dispatch]);

  useEffect(() => {
    setSelectedTab(tab ?? 0);
  }, [tab]);
  useEffect(() => {
    dispatch(fetchUserDetails(id));
  }, [id]);
  const user = useSelector((state) => state.userDetails.data);
  const [ mobileView, setMobileView ] = useState( false );
  const [ sm, updateSm ] = useState( false );
  let navigate = useNavigate();
  const toggleUpdateProfileImageModal = () =>
  {
      setUpdateProfileImageModal( !updateProfileImageModal );
  };

  const viewChange = () =>
  {
      if ( window.innerWidth < 990 )
      {
          setMobileView( true );
      } else
      {
          setMobileView( false );
          updateSm( false );
      }
  };
  useEffect( () =>
  {
      viewChange();
      window.addEventListener( "load", viewChange );
      window.addEventListener( "resize", viewChange );
      document
          .getElementsByClassName( "nk-header" )[ 0 ]
          .addEventListener( "click", function ()
          {
              updateSm( false );
          } );
      return () =>
      {
          window.removeEventListener( "resize", viewChange );
          window.removeEventListener( "load", viewChange );
      };
  }, [] );
  function handleAnchorClick ( event, sectionId )
  {
      event.preventDefault();

      const fixedTopBarHeight = 100;

      const contentSection = document.getElementById( sectionId );
      if ( contentSection )
      {
          const top = contentSection.getBoundingClientRect().top + window.pageYOffset - fixedTopBarHeight;
          window.scrollTo( {
              top: top,
              behavior: 'smooth'
          } );
      }
  }

  return (
    <React.Fragment>
      <Content>
        <BackButton />
        <Card className="card-bordered">
          <div className="card-aside-wrap">
          <div
                            className={ `card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${ sm ? "content-active" : ""
                                }` }
                        >
                            <div className="card-inner-group">
                                <div className="card-inner">
                  <div className="user-card">
                    {user?.profile_image &&
                    <UserAvatar text={findUpper("John Sam")} theme="primary"  image={user?.profile_image}/>}
                    {!user?.profile_image &&
                    <UserAvatar text={findUpper("John Sam")} theme="primary"  />
                    }
                    <div className="user-info">
                      <span className="lead-text">{startCase(user?.full_name)}</span>
                      <span className="sub-text">{user?.email}</span>
                    </div>
                    <div className="user-action">
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="btn btn-icon btn-trigger me-n2">
                          <Icon name="more-v"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={ ( ev ) =>
                                  {
                                      ev.preventDefault();
                                      toggleUpdateProfileImageModal();
                                  } }
                              >
                                <Icon name="camera-fill"></Icon>
                                <span>Change Photo</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="edit-fill"></Icon>
                                <span>Update Profile</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>

                <div className="card-inner p-0">
                  <ul className="link-list-menu">
                    {user?.userType!==USER_TYPES.Admin &&
                  <li
                    onClick={ () =>
                        updateSm( false )
                    }
                >
                    <a href="#user-profile-documents" onClick={ ( e ) => handleAnchorClick( e, 'user-profile-documents' ) }>
                        <Icon name="lock-alt-fill"></Icon>
                        <span>Documents</span>
                    </a>
                </li>}
                  <li
                    onClick={ () =>
                        updateSm( false )
                    }
                >
                    <a href="#user-profile" onClick={ ( e ) => handleAnchorClick( e, 'user-profile' ) }>
                        <Icon name="lock-alt-fill"></Icon>
                        <span>Personal Information</span>
                    </a>
                </li>
                { user?.user_register_type===UserRegisterTypes.Dealer &&
                  <li
                    onClick={ () =>
                        updateSm( false )
                    }
                >
                    <a href="#user-profile-sub" onClick={ ( e ) => handleAnchorClick( e, 'user-profile-sub' ) }>
                        <Icon name="lock-alt-fill"></Icon>
                        <span>Subscription</span>
                    </a>
                </li>}
                {(user?.userType===USER_TYPES.User || user?.user_register_type) &&
                  <li
                    onClick={ () =>
                        updateSm( false )
                    }
                >
                    <a href="#user-auctions" onClick={ ( e ) => handleAnchorClick( e, 'user-auctions' ) }>
                        <Icon name="lock-alt-fill"></Icon>
                        <span>Auctions</span>
                    </a>
                </li>
                }   
     {(user?.userType===USER_TYPES.User || user?.user_register_type) &&
     <>
                  <li
                    onClick={ () =>
                        updateSm( false )
                    }
                >
                    <a href="#user-history" onClick={ ( e ) => handleAnchorClick( e, 'user-history' ) }>
                        <Icon name="lock-alt-fill"></Icon>
                        <span>History</span>
                    </a>
                </li>
                <li
                    onClick={ () =>
                        updateSm( false )
                    }
                >
                    <a href="#user-review" onClick={ ( e ) => handleAnchorClick( e, 'user-review' ) }>
                        <Icon name="lock-alt-fill"></Icon>
                        <span>Reviews</span>
                    </a>
                </li>
                </>
                }    
                              
{/* 
                    <li onClick={() => setSelectedTab(0)}>
                      <Link
                        to={`personalInformation`}
                        className={window.location.pathname === `/users/${id}/personalInformation` ? "active" : ""}
                      >
                        <Icon name="user-fill-c"></Icon>
                        <span>Personal Information</span>
                      </Link>
                    </li>
           
                    <li onClick={() => setSelectedTab(2)}>
                      <Link
                        to={`documents`}
                        className={window.location.pathname === `/users/${id}/documents` ? "active" : ""}
                      >
                        <Icon name="activity-round-fill"></Icon>
                        <span>Documents</span>
                      </Link>
                    </li>
               */}
                  </ul>
                </div>
              </div>
            </div>
            <div style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal' }} className="card-inner">
              { sm && mobileView && (
                  <div
                      className="toggle-overlay"
                      onClick={ () => updateSm( !sm ) }
                  ></div>
              ) }
       {user?.userType!==USER_TYPES.Admin &&   
              <div
                  id="user-profile-documents"
                  className="mb-5"
              >               
              <UserDocumentsPage userDetails={user}/>                    
              </div>
              }
              <div id="user-profile" className="mb-5">
                  <UserProfileRegularPage
                  />
              </div>
              { user?.user_register_type===UserRegisterTypes.Dealer &&
                 <div id="user-profile-sub" className="mb-5">
                 <UserSubscription user={user}
                 />
             </div>
              }
          {user &&  (user?.userType===USER_TYPES.User || user?.user_register_type) &&
                          <div id="user-auctions" className="mb-5 bg-lighter" 
                          style={{
                            backgroundColor: "light",
                            borderRadius: 4,
                            marginTop: 16,
                            marginBottom: 16,
                          }}>
                              {/* <AllAuctions/> */}
                                   <div
                                    id="user-review"
                                    className="mb-5">               
                                <Reviews/>                   
                                    </div>
                          
                      </div>
          }
               {(user?.userType===USER_TYPES.User || user?.user_register_type) &&
              <div
                  id="user-history"
                  className="mb-5"
              >               
              <UserHistoryPage />                    
              </div>
              
              }
              <Modal
                        size="lg"
                        isOpen={ updateProfileImageModal }
                        toggle={ toggleUpdateProfileImageModal }
                    >

                        <EditUserProfileImage
                            toggle={ toggleUpdateProfileImageModal }
                            user={ user }
                            
                        />
                    </Modal>
          </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
}
