import React from "react";
import { Icon } from "../Component";
import DateFilter from "../date-filter/DateFilter";

export default function ListBlockHeader({ placeholder="Search",msg="",title, onSearchChanged, onDateChanged,onSearchText='', children }) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "flex-end",
        marginRight:'20px',
        margin: "20px",
        columnGap: "20px",
        rowGap: "20px",
      }}
    >
      <div><h3 style={{ flex: 3 }}>{title}</h3><span>{msg}</span></div>
      <div className="form-control-wrap" style={{ flexShrink: 0, flexGrow: 1 }}>
        <div className="form-icon form-icon-right">
          <Icon name="search"></Icon>
        </div>
        <input
        value={onSearchText}
          type="text"
          className="form-control"
          id="default-04"
          placeholder={placeholder}
          onChange={onSearchChanged}
        />
      </div>
      {/* <DateFilter  onChange={onDateChanged} /> */}
      {children}
    </div>
  );
}
