import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Spinner, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap";
import { useDebounce } from "use-debounce";
import {
  fetchDirectSales,
  debouncedFetchDirectSales,
  clearItems,
  setAllSelected,
  setSelected,
} from "../../redux/features/directSaleSlice/directSaleSlice";
import { fetchVerticalDetails } from "../../redux/features/verticalsSlice/verticalDetailsSlice";
import Content from "../../layout/content/Content";
import ListBlockHeader from "../../components/list-block-header/ListBlockHeader";
import TableComponent from "../../components/table/TableComponent";
import { getColor } from "../../utils/getColor";
import { verticalId } from "../../utils/configEnv";
import CreateBulkAuctionButton from "../cars/components/CreateBulkAuctionButton";
import ReactDatePicker from "react-datepicker";
import FilterSection from "./components/FilterSection";

const DirectSaleItems = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    searchText: "",
    status: "",
    dateRange: [null, null],
    currentPage: 1,
    sortByField: "createdAt",
    sortByValue: -1,
    vertical: "",
  });

  const { data, totalCount, loading, perPage, selected } = useSelector((state) => state.directSales);
  const { loggedInUser: user } = useSelector((state) => state.auth);
  const verticals = useSelector((state) => state.verticals);

  const [debouncedSearchText] = useDebounce(filters.searchText, 500);
  const verticalItemGet = localStorage.getItem("verticalItemGetValue") || "";

  useEffect(() => {
    dispatch(fetchVerticalDetails(verticalId));
  }, [dispatch]);

  // Fetch data based on filters
  const fetchData = useCallback(() => {
    const { currentPage, sortByField, sortByValue, status, dateRange, vertical, searchText } = filters;

    const requestData = {
      query: {},
      options: {
        page: currentPage,
        limit: perPage,
        sort: sortByValue === 1 ? sortByField : `-${sortByField}`,
        populate: [{ path: "user_id", select: "full_name" }],
      },
    };

    if (vertical) {
      requestData.query.vertical_id = vertical.id;
    } else if (verticalItemGet) {
      requestData.query.vertical_id = JSON.parse(verticalItemGet).id;
    }

    if (status) requestData.query.status = status;
    if (dateRange[0] && dateRange[1]) {
      requestData.query.date = {
        startDate: dateRange[0].toISOString(),
        endDate: dateRange[1].toISOString(),
      };
    }
    if (user?.id) requestData.query.user = user.id;
    if (searchText) {
      requestData.query.search = searchText.trim();
      dispatch(debouncedFetchDirectSales(requestData));
    } else {
      dispatch(fetchDirectSales(requestData));
    }
  }, [filters, dispatch, user?.id, perPage, verticalItemGet]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Handle pagination and sorting
  const handlePageChange = (newPage) => setFilters((prev) => ({ ...prev, currentPage: newPage }));

  const handleSortChange = (field, direction) => {
    setFilters((prev) => ({
      ...prev,
      sortByField: field,
      sortByValue: direction,
      currentPage: 1,
    }));
  };

  const handleSearchChange = (event) => {
    setFilters(prev => ({ ...prev, searchText: event.target.value, currentPage: 1 }));
  };

  const handleDateFilterChange = (date) => {
    setFilters(prev => ({ ...prev, dateRange: date, currentPage: 1 }));
  };

  const handleStatusChange = (event) => {
    setFilters(prev => ({ ...prev, status: event.target.value, currentPage: 1 }));
  };

  const handleSelectAll = (checked) => {
    dispatch(setAllSelected({ value: checked }));
  };
  const handleSelectRow = (id, checked) => {
    dispatch(setSelected({ id, value: checked }));
  };

  const clearFilters = () => {
    setFilters({
      searchText: '',
      dateRange: [null, null],
      status: '',
      currentPage: 1,
      sortByField: 'createdAt',
      sortByValue: -1
    });
  };

  const columns = [
    {
      label: "ID",
      field: "baseform_id",
      sortable: true,
      render: (item) => <Link to={`/direct-sales/${item.id}`}>{item?.baseform_id}</Link>,
    },
    {
      label: "Title",
      field: "title",
      sortable: true,
      render: (item) => <Link to={`/direct-sales/${item.id}`}>{item?.title}</Link>,
    },
    {
      label: "User",
      field: "user_id",
      sortable: false,
      render: (item) => item?.user_id?.full_name,
    },
    {
      label: "Status",
      field: "status",
      sortable: true,
      render: (item) => (
        <Badge color={getColor(item?.status)}>{item?.status}</Badge>
      ),
    },
    {
      label: "Created At",
      field: "createdAt",
      sortable: true,
      render: (item) => new Date(item?.createdAt).toLocaleString(),
    },
  ];
console.log(data)
  return (
    <React.Fragment>
      <Content>
        <FilterSection
          filters={filters}
          handleDateRangeChange={handleDateFilterChange}
          handleSearchChange={handleSearchChange}
          handleStatusChange={handleStatusChange}
          clearFilters={clearFilters}
        />

        <TableComponent
          data={data}
          columns={columns}
          totalCount={totalCount}
          perPage={perPage}
          currentPage={filters.currentPage}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          loading={loading}
          selectedRows={selected}
          onSelectAll={handleSelectAll}
          onSelectRow={handleSelectRow}
        />

      

        
      </Content>
    </React.Fragment>
  );
};

export default DirectSaleItems;
