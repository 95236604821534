import React, { memo, useEffect, useState } from 'react';
import Content from '../../layout/content/Content';
import {
  BackButton,
  Block,
  Carousal,
  Image,
  PaginationComponent
} from '../../components/Component';
import {
  Box,
  Button,
  Grid2,
  List,
  Paper,
  Stack,
  TextField,
  Typography,
  ListItem,
  Container,
  Alert
} from '@mui/material';
import { Col, Row, Spinner } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  connectToAuctionGroupEvents,
  disconnectFromAuctionGroupEvents,
  disconnectFromFirestore,
  fetchAuctionGroupDetails,
  fetchAuctionsInAuctionGroups,
  resetSelectWinner,
  resetSuccessfulBids,
  resetUpdateAuctionsOrder,
  updateAuctionsOrder
} from '../../redux/features/auctionSlice/auctionGroupDetailsSlice';
import { useSelector } from 'react-redux';
import AuctionItem from './components/AuctionItem';
import LiveAuctionTimerWithScale from '../auctions/components/LiveAuctionTimerWithScale';
import { auctionGroupStatus, auctionStatus } from '../../utils/Utils';
import { areEqual, FixedSizeList } from 'react-window';
import _, { memoize } from 'lodash';
import BidItem from './components/BidItem';
import CarInfoDetails from './components/CarInfoDetails';
import StartAuctionButton from './components/StartAuctionButton';
import LiveAuctionBiddingSection from './components/LiveAuctionBiddingSection';
import BottomStickyBar from './components/BottomStickyBar';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AuctionReorder from './components/AuctionReorder';
import { toast } from 'react-toastify';
import CarDetails from '../cars/components/CarDetailsList';
import ImageCarousel from './components/ImageCarousel';
import { CompletedScreen } from './components/CompletedScreen';
import AddItemToGroupButtonWithModal from './components/AddItemToGroupButtonWithModal';
import VirtualizedAuctionList from './components/NextAuctionsList';
import CancelAuctionGroup from './components/CancelAuctionGroup';
import { WarningRounded } from '@mui/icons-material';

export const Context = React.createContext();

const f = (a, b) =>
  typeof a === 'object' && typeof b === 'object' ? !_.isEqual(a, b) : a !== b;

export default function AuctionGroupDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    data,
    loading,
    error,
    auctions,
    updateAuctionsOrderStatus,
    updateAuctionsOrderError,
    selectAuctionWinnerStatus,
    selectAuctionWinnerError
  } = useSelector(
    (state) => state.auctionGroupDetails,
    (a, b) =>
      a.auctionGroupDetails?.data !== b.auctionGroupDetails?.data ||
      a.AuctionGroupDetails?.auctions !== b.auctionGroupDetails?.auctions ||
      a.auctionGroupDetails?.updateAuctionsOrderStatus !==
        b.auctionGroupDetails?.updateAuctionsOrderStatus ||
      a.auctionGroupDetails?.selectAuctionWinnerStatus !==
        b.auctionGroupDetails?.selectAuctionWinnerStatus
  );
  const [auctionStats, setAuctionStats] = useState(auctionStatus.UPCOMING);
  const [nonLiveAuctions, setNonLiveAuctions] = useState([]);
  const [updatedOrder, setUpdatedOrder] = useState(false);

  useEffect(() => {
    setNonLiveAuctions(auctions);
  }, [auctions?.length]);

  useEffect(() => {
    dispatch(connectToAuctionGroupEvents({ auctionGroupId: data?.id }));

    return () => {
      dispatch(disconnectFromAuctionGroupEvents());
      dispatch(disconnectFromFirestore());
    };
  }, [data?.id]);

  useEffect(() => {
    dispatch(fetchAuctionGroupDetails(id));
    dispatch(fetchAuctionsInAuctionGroups(id));
  }, [id]);

  useEffect(() => {
    if (updateAuctionsOrderStatus === 'success') {
      toast.success('Successfully saved new order');
      setNonLiveAuctions();
      setUpdatedOrder(false);
      dispatch(resetUpdateAuctionsOrder());
    } else if (updateAuctionsOrderStatus === 'error') {
      toast.error('Error : ' + updateAuctionsOrderError);
      dispatch(resetUpdateAuctionsOrder());
    }
  }, [updateAuctionsOrderStatus]);

  const handleSaveReorder = () => {
    const auctions = nonLiveAuctions.map((e) => ({
      id: e.id,
      sequence_position: e.sequence_position
    }));
    dispatch(updateAuctionsOrder({ id: data?.id, body: { auctions } }));
  };

  // Check if all auctions have ended
  const allAuctionsEnded = data?.status === auctionGroupStatus.COMPLETED;

  useEffect(() => {
    if (selectAuctionWinnerStatus === 'success') {
      toast.success('Congratulation, successfully selected winner');
      dispatch(resetSuccessfulBids());
      dispatch(resetSelectWinner());
    } else if (
      selectAuctionWinnerStatus === 'error' &&
      selectAuctionWinnerError
    ) {
      toast.error('Error : ' + selectAuctionWinnerError); // Show error message only once
      dispatch(resetSelectWinner());
    }
  }, [selectAuctionWinnerStatus]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  if (allAuctionsEnded) {
    return <CompletedScreen auctionGroup={data} />;
  }

  return (
    <React.Fragment>
      <Content>
        <div style={{ marginBottom: 100 }}>
          <Stack direction="row" justifyContent="space-between">
            <BackButton />
          </Stack>
          {data?.status === auctionStatus.LIVE ? (
            <Grid2 container spacing={2}>
              <Grid2 item size={4}>
                <Paper elevation={0}>
                  <Box
                    height="400px"
                    bgcolor="black"
                    alignContent="center"
                    sx={{ overflow: 'hidden' }}
                  >
                    <ImageCarousel
                      images={data?.current_auction?.item?.images ?? []}
                    />
                  </Box>
                </Paper>

                <Paper
                  elevation={0}
                  sx={{ height: 1, padding: 2, marginTop: 2 }}
                >
                  <CarDetails data={data?.current_auction?.item} />
                </Paper>
              </Grid2>

              <Grid2 item size={4}>
                <Grid2 container direction="column" spacing={2}>
                  <Grid2 item>
                    <Paper elevation={0} sx={{ height: 'auto', padding: 2 }}>
                      <LiveAuctionBiddingSection
                        auction={data?.current_auction}
                        auctionStats={auctionStats}
                        setAuctionStats={setAuctionStats}
                      />
                    </Paper>
                  </Grid2>
                  <Grid2 item>
                    <Paper elevation={0} sx={{ height: '500px', padding: 2 }}>
                      <BidItem
                        id={data?.current_auction?.id}
                        auctionGroupId={data?.id}
                      />
                    </Paper>
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 item size={4} height={'800px'}>
                <Paper elevation={0} sx={{ height: '800px' }}>
                  <Box padding={2} height="800px">
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={2}
                      paddingBottom={2}
                    >
                      <Typography variant="h5" fontWeight="bold">
                        Next Auctions{' '}
                      </Typography>
                      <Typography variant="body1" color="gray" fontSize={12}>
                        {auctions?.length} Auctions
                      </Typography>
                    </Stack>
                    <VirtualizedAuctionList auctions={auctions} />
                    {/* <Box height="700px" sx={{ overflowY: 'auto' }}>
                      <List>
                        {auctions && auctions.length > 0
                          ? auctions.map((e) => (
                              <ListItem key={e.id} sx={{ mb: 1 }}>
                                <AuctionItem auction={e} />
                              </ListItem>
                            ))
                          : 'No Pending Auctions'}
                      </List>
                    </Box> */}
                  </Box>
                </Paper>
              </Grid2>
            </Grid2>
          ) : (
            <Grid2 size={12}>
              <Paper sx={{ padding: 2 }}>
                <Stack direction="row" justifyContent="space-between">
                  <Box padding={2}>
                    <Typography variant="h6" component="h4" fontWeight="bold">
                      Auctions
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      You can rearrange the list to change the order of auction
                    </Typography>
                  </Box>
                 {data?.status !=4&& <Stack
                    direction="row"
                    alignSelf="center"
                    justifyContent="flex-end"
                  >
                    <StartAuctionButton id={id} />
                    <CancelAuctionGroup id={id} />
                  </Stack>}
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  {/* Cancelled Alert */}
                  {data?.status == 4&& <Alert
                  sx={{margin:2}}
                    severity="error"
                    icon={<WarningRounded className="w-5 h-5" />}
                    className="mb-6"
                  >
                    This auction has been cancelled
                  </Alert>}
                  
                  <Box marginBottom={1}>
                  <AddItemToGroupButtonWithModal id={data?.id} />
                    {updatedOrder && (
                      <Button
                        variant="outlined"
                        onClick={handleSaveReorder}
                        sx={{ margin: 2 }}
                      >
                        Save
                      </Button>
                    ) }
                  </Box>
                </Stack>
                <>
                  {' '}
                  {auctions?.length && (
                    <AuctionReorder
                      auctions={auctions}
                      onLayoutChange={(items) => {
                        setNonLiveAuctions(items);
                        setUpdatedOrder(true);
                      }}
                    />
                  )}
                </>
              </Paper>
            </Grid2>
          )}
          <Box display={data?.status === auctionStatus.LIVE ? 'block' : 'none'}>
            {' '}
            <BottomStickyBar />
          </Box>
        </div>
      </Content>
    </React.Fragment>
  );
}
