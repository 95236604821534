import { Box } from '@mui/material';
import React from 'react';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import AuctionItem from './AuctionItem';

const VirtualizedAuctionList = ({ auctions = [] ,noAuctionText}) => {
  // Only render if we have auctions
  if (!auctions?.length) {
    return (
      <Box className="flex items-center justify-center text-gray-500" height={700}>
       {noAuctionText|| "No Pending Auctions"}
      </Box>
    );
  }

  // Function to render each row
  const rowRenderer = ({ key, index, style }) => {
    const auction = auctions[index];
    return (
      <div key={key} style={style} className="px-4 py-2">
        <AuctionItem auction={auction} />
      </div>
    );
  };

  return (
    <Box height={700}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            width={width}
            height={height}
            rowCount={auctions.length}
            rowHeight={120} // Adjust this based on your AuctionItem height
            rowRenderer={rowRenderer}
            overscanRowCount={3} // Number of items to render above/below visible area
            className="outline-none"
          />
        )}
      </AutoSizer>
    </Box>
  );
};

export default VirtualizedAuctionList;