import React, { useEffect, useState } from 'react'
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import {  Icon, } from '../../../components/Component'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { Button } from '@mui/material'
import { createBulkDirectSale, resetBulkDirectSaleCreateState } from '../../../redux/features/itemsSlice/itemsSlice'



export default function CreateBulkDirectSaleButton() {

    const { createBulkDirectSaleStatus,
        createBulkDirectSaleError,
    } = useSelector((state) => state.items,(a,b)=>a?.items?.createBulkDirectSaleStatus !== b?.items?.createBulkDirectSaleStatus)


    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };


    const methods = useForm({});
    const { handleSubmit,  } = methods;

    const dispatch = useDispatch();

    useEffect(() => {
        if (createBulkDirectSaleStatus === "success") {
            toast.success("Auctions Created Successfully");
            setIsOpen(false);
            dispatch(resetBulkDirectSaleCreateState())
        } else if (createBulkDirectSaleStatus === "error") {
            toast.error(`Auctions creating failed, ${createBulkDirectSaleError}`);
            dispatch(resetBulkDirectSaleCreateState())

        }
    }, [createBulkDirectSaleStatus, createBulkDirectSaleError,dispatch]);

    const handleBulkAuctionCreation = async (data) => {
        dispatch(createBulkDirectSale({}));
    };

    return (
        <div>
            <div className="me-3">
                <Button variant='contained' onClick={toggle}>
                    List As Direct Sale
                </Button>
            </div>
            <Modal isOpen={isOpen}>
                <form onSubmit={handleSubmit(handleBulkAuctionCreation)}>
                    <ModalHeader
                        close={
                            <button className="close" onClick={toggle} type="button">
                                <Icon name="cross" />
                            </button>
                        }
                    >
                        List As Direct Sale
                    </ModalHeader>
                    <ModalBody>
                        Confirm to move items to direct sale    
                    </ModalBody>
                    <ModalFooter>
                        <Row>
                            <Col>
                                <Button color="light" onClick={toggle} style={{ width: "100%" }}>
                                    Cancel
                                </Button>
                            </Col>
                            <Col>
                                <Button color="primary" type='submit' style={{ width: "100%" }}>
                                    {createBulkDirectSaleStatus === 'loading' ? <Spinner size="sm" /> : "Confirm"}
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </form>
            </Modal>
        </div >
    )
}
