import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import carDetailsSlice, { updateCarInspection } from "../itemsSlice/itemDetailsSlice";
import { useDispatch } from "react-redux";

const initialState = {
  status: null,
  loading: false,
  error: null,
};

const updateInspection = createAsyncThunk("inspection/update", (params, { dispatch }) => {
  const { id, data } = params;
  let url = `/v1/device/api/v1/inspection/${id}`;
  return axios.post(url, data).then((response) => {
    dispatch(updateCarInspection(response.data));
    return response.data;
  });
});

const inspectionSlice = createSlice({
  name: "inspection",
  initialState,
  reducers: {
    resetUpdateStatus: (state) => {
      state.status = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateInspection.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.status = null;
    });
    builder.addCase(updateInspection.fulfilled, (state, action) => {
      state.status = "success";
      state.loading = false;
    });
    builder.addCase(updateInspection.rejected, (state, action) => {
      state.status = "error";
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default inspectionSlice.reducer;
export const { resetUpdateStatus } = inspectionSlice.actions;
export { updateInspection };
