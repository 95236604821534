import React, { useState, useEffect } from 'react';
import Content from '../../layout/content/Content';
import { Link } from 'react-router-dom';
import {
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Carousal,
    Col,
    Row
} from '../../components/Component';
import { Badge, } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchCarDetails } from '../../redux/features/itemsSlice/itemDetailsSlice';
import { Grid2, Paper, } from '@mui/material';
import { useSelector } from 'react-redux';
import BackButton from '../../components/button/BackButton';
import { startCase } from 'lodash';
import CarDetails from '../cars/components/CarDetailsList';


export default function DirectSaleDetails() {


    const carDetails = useSelector((state) => state.carDetails.data,(a,b)=>a.carDetails?.data !== b.carDetails?.data);

    const { id } = useParams();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCarDetails(id));
    }, []);


   

    return (
        <React.Fragment>
            <Content>
                <BackButton />
                <Grid2 container spacing={2} >
                    <Grid2 item size={8} >
                        <Paper
                            sx={{ objectPosition: 'center', width: '100%', height: '36rem', overflow: 'clip' }}
                        >
                            {carDetails && <Carousal images={carDetails.images} id={id} />}
                        </Paper>
                    </Grid2>
                    <Grid2 item size={4}>
                        <Paper
                            elevation={0}
                            sx={{ height: 1, color: '#ffffff', padding: 4 }}
                        >
                            <BlockHead size="lg">
                                <BlockBetween>
                                    <BlockHeadContent>
                                        <BlockTitle tag="h4">Item Details</BlockTitle>
                                        <BlockDes>
                                            <p>Basic information</p>
                                            <Badge>{startCase(carDetails?.sale_type)}</Badge>
                                        </BlockDes>
                                    </BlockHeadContent>
                                </BlockBetween>
                            </BlockHead>

                            <ul className="nk-store-statistics">
                                <li>
                                    <Row>
                                        <Col sm="6">
                                            <div className="info">
                                                <div className="title">ID</div>
                                                <div className="count-details">
                                                    {carDetails?.baseform_id}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="info">
                                                <div className="title">Owner</div>
                                                <div className="count-details">
                                                    <Link to={`/users/${carDetails?.user_id?.id}`}>
                                                        {`${startCase(carDetails?.user_id?.full_name)}`}
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="info">
                                                <div className="title">Price</div>
                                                <div className="count-details">
                                                    AED {carDetails?.direct_sale_price}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </li>

                                <CarDetails data={carDetails} />

                            </ul>
                        </Paper>
                    </Grid2>

                </Grid2>
            </Content>
        </React.Fragment>
    );
}
