import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { fetchAuctionDetails } from "./auctionDetailsSlice";

const initialState = {
  status: "init",
  loading: false,
  error: null,
};

const setReserveMet = createAsyncThunk("auctions/reserveMet", (data) => {
  const { id, body } = data;
  let url = `/device/api/v1/auction-group/reserve-met/${id}`;
  return axios.put(url, body).then((response) => {
    return response.data;
  });
});
const reserveMetSlice = createSlice({
  name: "setReserveMet",
  initialState,
  reducers: {
    resetReserveMet: (state) => {
      state.status = "init";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setReserveMet.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = {};
    });
    builder.addCase(setReserveMet.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.status = "success";
    });
    builder.addCase(setReserveMet.rejected, (state, action) => {
      state.loading = false;
      state.status = "error";
      state.error = action.error.message;
    });
  },
});

export default reserveMetSlice.reducer;
export const { resetReserveMet } = reserveMetSlice.actions;
export { setReserveMet };
