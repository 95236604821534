import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Button, Col, Spinner } from 'reactstrap';
import { createUser } from '../../redux/features/usersSlice/userDetails';
import { FormSelect, FormTextField } from '../../components/Component';
import FormPhoneField from '../../components/form/FormPhoneField';
import FormMultiSelect from '../../components/form/FormMultiSelect';
import { Emirates } from '../../utils/Utils';
import FormUpload from '../../components/form/FormUpload';
import { uploadToS3 } from '../../utils/uploadImageToS3';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';


export default function AddDealerForm({ open, setOpen }) {
    const methods = useForm();
    const { handleSubmit, control } = methods;
    const { createLoading } = useSelector((state) => state.userDetails)
    const dispatch = useDispatch();





    const onSubmit = async (data) => {

        const formData = {
            ...data,
            is_phone_verified: true,
            is_email_verified: true,
            is_verified: "approved",
            user_type: 1,
            user_register_type: 'Dealer',
            dealer_type: "Company",

        };
        dispatch(createUser(formData))
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Col sm="12">
                <FormTextField
                    label="Company Name*"
                    name="company_name"
                    control={control}
                />
            </Col>
            <Col sm="12">
                <FormTextField
                    label="Full Name*"
                    name="full_name"
                    control={control}
                />
            </Col>
            <Col sm="12">
                <FormTextField
                    label="Email*"
                    name="email"
                    control={control}
                />
            </Col>
            <Col sm="12">
                <FormPhoneField
                    label="Phone*"
                    name="phone"
                    control={control}
                />
            </Col>
            <Col sm="12">
                <FormSelect
                    label="Emirate*"
                    name="emirate"
                    control={control}
                    options={Emirates}
                />
            </Col>
            <Col sm="12">
                <FormUpload
                    label="Trade License*"
                    name="trade_license"
                    control={control}
                />
            </Col>

            <Col sm="6">
                <Button
                    type="submit"
                    className="bg-primary text-white mt-2"
                >
                    {createLoading ? (
                        <>
                            <Spinner size="sm" color="light" />
                            Please Wait
                        </>
                    ) : (
                        'Submit'
                    )}
                </Button>
            </Col>
        </form>
    )
}
