import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { debounce } from "lodash";
import { fetchVerticals } from "./verticalsSlice";
import { fetchVerticalDetails } from "./verticalDetailsSlice";

const initialState = {
  data: {},
  status: "init",
  error: null,
};
const createOrUpdateFormFields = createAsyncThunk("createOrUpdateFormFields", (data, { dispatch }) => {
  let url;
  // console.log(data);
  switch (data.action) {
    case "create":
      switch (data.formType) {
        case "Basic":
          url = `/device/api/v1/verticalbaseform/create`;
          break;
        case "Simple":
          url = `/device/api/v1/inspectionformsimplified/create`;
          break;
        case "Advanced":
          url = `/device/api/v1/inspectionformadvanced/create`;
          break;
      }
      return axios.post(url, data.data).then((response) => {
        dispatch(fetchVerticalDetails(data.data.vertical_id));
        return response.data;
      });
    case "update":
      switch (data.formType) {
        case "Basic":
          url = `/device/api/v1/verticalbaseform/update/${data.id}`;
          break;
        case "Simple":
          url = `/device/api/v1/inspectionformsimplified/update/${data.id}`;
          break;
        case "Advanced":
          url = `/device/api/v1/inspectionformadvanced/update/${data.id}`;
          break;
      }
      return axios.put(url, data.data).then((response) => {
        dispatch(fetchVerticalDetails(data.data.vertical_id));
        return response.data;
      });
  }
});

const formFieldsSlice = createSlice({
  name: "createOrUpdateFormFields",
  initialState,
  reducers: {
    resetCreateOrUpdtaeFormFields: (state, action) => {
      state.data = null;
      state.status = "init";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createOrUpdateFormFields.pending, (state) => {
      state.status = "loading";
      state.error = null;
      state.data = null;
    });
    builder.addCase(createOrUpdateFormFields.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.status = "success";
      state.data = action.payload.data;
    });
    builder.addCase(createOrUpdateFormFields.rejected, (state, action) => {
      state.status = "error";
      state.data = null;
      state.error = action.error.message;
    });
  },
});

export default formFieldsSlice.reducer;
export const { resetCreateOrUpdtaeFormFields } = formFieldsSlice.actions;
export { createOrUpdateFormFields };
