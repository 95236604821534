import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { debounce } from "lodash";

const initialState = {
  data: [],
  loading: false,
  error: null,
  users: null,
};
const fetchRM = createAsyncThunk("RM", () => {
  let data = {
    "query": {
      "onlyRM": true
    }
  }
  let url = `/device/api/v1/user/list/`;
  return axios.post(url, data).then((response) => {
    return response.data;
  });
});
const fetchUsersOfRM = createAsyncThunk("usersOfRM", (data) => {

  let url = `/device/api/v1/user/list/rm`;
  return axios.post(url, data).then((response) => {
    return response.data;
  });
});
const clearUsersOfRM = createAsyncThunk('clearUsersOfRM', async () => {
  return null;
});

const rmSlice = createSlice({
  name: "RM",
  initialState,
  reducers: {
    // setSelected: (state, action) => {
    //   const { index, value } = action.payload;
    //   // console.log(index);
    //   let data = state.data;
    //   data[index].check = value;
    //   state.data = data;
    // },
    // setAllSelected: (state, action) => {
    //   const { value } = action.payload;
    //   // console.log(value);
    //   let data = state.data;
    //   state.data = data.map((e) => {
    //     e.check = value;
    //     return e;
    //   });
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRM.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchRM.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data ?? null;
    });
    builder.addCase(fetchRM.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(fetchUsersOfRM.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.users = null;
    });
    builder.addCase(fetchUsersOfRM.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.users = action.payload?.data ?? null;
    });
    builder.addCase(fetchUsersOfRM.rejected, (state, action) => {
      state.loading = false;
      state.users = null;
      state.error = action.error.message;
    });
    builder.addCase(clearUsersOfRM.fulfilled, (state) => {
      state.users = null;
    });
  },
});

export default rmSlice.reducer;
// export const { setSelected, setAllSelected } = rolesSlice.actions;
export { fetchRM, fetchUsersOfRM, clearUsersOfRM };
