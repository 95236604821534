import { Button } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resetStartAuction, startLiveAuction } from '../../../redux/features/auctionSlice/auctionGroupDetailsSlice';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';



export default function StartAuctionButton({ id }) {
    const dispatch = useDispatch();
    const { startLiveStatus, startLiveError } = useSelector((state) => state.auctionGroupDetails)

    const handleStartAuction = () => {
        dispatch(startLiveAuction(id))
    }

    useEffect(() => {
        if (startLiveStatus === 'success') {
            toast.success('Live Auction started successfully')
            dispatch(resetStartAuction())
        } else if (startLiveStatus === 'error') {
            toast.error(`Error: ${startLiveError}`)
            dispatch(resetStartAuction())

        }

    }, [startLiveStatus, startLiveError])
    return (
        <Button variant='contained' sx={{ margin: 2 }} onClick={handleStartAuction}>Start Auction</Button>
    )
}
