import { Button } from '@mui/material';
import React from 'react'
import { useDispatch } from 'react-redux';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import { Icon } from '../../../components/Component';
import { cancelAuctionGroup } from '../../../redux/features/auctionSlice/cancelAuctionGroup';

export default function CancelAuctionGroup({id}) {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const toggle = () => setIsOpen(!isOpen);
    

    const handleAction =async () => {
       try {
        setLoading(true);
        await dispatch(cancelAuctionGroup(id))?.unwrap();
       } catch (error) {
        
       }finally {
        setLoading(false);
        setIsOpen(false);
       }
    }

    return (
        <>
        <Button variant='contained' color='error' sx={{ margin: 2 }} onClick={toggle}>Cancel</Button>
        <Modal isOpen={isOpen} >
              
                    <ModalHeader
                        close={
                            <button className="close" onClick={toggle} type="button">
                                <Icon name="cross" />
                            </button>
                        }
                    >
                        Cancel Scheduled Auction Group
                    </ModalHeader>
                    <ModalBody>
                       <div>
                            <h6>Are you sure you want to cancel this scheduled auction group?</h6>
                            <p>This action cannot be undone.</p>
                        </div>
                    
                      
                
                    </ModalBody>
                    <ModalFooter>
                        <Row>
                            <Col>
                                <Button color="light" onClick={toggle} style={{ width: "100%" }} type="button">
                                    Cancel
                                </Button>
                            </Col>
                            <Col>
                                <Button color="primary" onClick={handleAction} style={{ width: "100%" }}>
                                    {loading? <Spinner size="sm" /> : "Cancel"}
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>

            </Modal>
        </>
    )
}
