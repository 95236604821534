import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LiveAuctionTimerWithScale from '../../auctions/components/LiveAuctionTimerWithScale';
import { useDispatch } from 'react-redux';
import { auctionStatus } from '../../../utils/Utils';
import { useSelector } from 'react-redux';
import {
  extendAuction,
  nextLiveAuction,
  placeBidGroup,
  resetPlacedBids
} from '../../../redux/features/auctionSlice/auctionGroupDetailsSlice';
import Circle from './CircleComponent';
import { toast } from 'react-toastify';
import SelectWinnerModalButton from './SelectWinnerModalButton';

export default function LiveAuctionBiddingSection({
  auction,
  auctionStats,
  setAuctionStats
}) {
  const dispatch = useDispatch();
  const {
    currentBid,
    data,
    extendAuctionStatus,
    extendAuctionError,
    placeBidStatus,
    placeBidError
  } = useSelector((state) => state.auctionGroupDetails);
  const details = data.current_auction;
  const [bidCustomValue, setBidCustomValue] = useState(null);

  const onPlaceBid = (amount) => {
    dispatch(placeBidGroup(amount));
  };

  const onSkipAndContinue = () => {
    dispatch(nextLiveAuction(auction?.id));
  };
  const onExtendAuction = async (duration) => {
    try {
      await dispatch(extendAuction({ id: auction?.id, duration }))?.unwrap();
      toast.success('Successfully extended auction');
    } catch (err) {
      toast.error('Error : ' + extendAuctionError);
    }
  };

  useEffect(() => {
    if (placeBidStatus === 'success') {
      dispatch(resetPlacedBids());
      toast.success('Successfully placed bid');
    } else if (placeBidStatus === 'error') {
      dispatch(resetPlacedBids());
      toast.error('Error : ' + placeBidError);
    }
  }, [placeBidStatus, placeBidError]);
  const smallButtonStyle = {
    minWidth: 24, // Very small width
    padding: '2px 4px', // Very small padding
    fontSize: '10px', // Small font size
    margin: '0 3px', // Small margin
    textTransform: 'none', // Keep the text as is, no uppercase
    borderColor: '#D9D9D9', // Optional: Define a specific border color if needed
    color: 'black',
    bgcolor: '#D9D9D9'
  };
  const bidButtonStyle = {
    padding: '2px 10px', // Very small padding
    fontSize: '13px', // Small font size
    margin: '0 3px', // Small margin
    textTransform: 'none', // Keep the text as is, no uppercase
    borderColor: '#D9D9D9', // Optional: Define a specific border color if needed
    color: 'black',
    bgcolor: '#D9D9D9',
    elevation: 0
  };
  return (
    <Stack
      spacing={2}
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      justifyItems={'center'}
    >
      <LiveAuctionTimerWithScale
        auction={details}
        startTime={details?.start_time}
        duration={details?.duration}
        setAuctionStats={setAuctionStats}
        auctionStats={details?.status}
      />
      {
        <Stack direction="row" spacing={1} alignItems="center">
          <Button
            variant="outlined" // Only use outlined variant
            onClick={() => onExtendAuction(60000)}
            sx={smallButtonStyle}
          >
            1 min
          </Button>
          <Button
            variant="outlined" // Only use outlined variant
            onClick={() => {
              onExtendAuction(10000);
            }}
            sx={smallButtonStyle}
          >
            10 s
          </Button>
          <Button
            variant="outlined" // Only use outlined variant
            onClick={() => {
              onExtendAuction(20000);
            }}
            sx={smallButtonStyle}
          >
            20 s
          </Button>
        </Stack>
      }
      <Stack direction="row" spacing={1} alignItems="center">
        <Circle auction={details} />
        <Typography fontSize={12}>Current High Bid : </Typography>
        <Typography color="primary" fontWeight="bold" fontSize={20}>
          {`${currentBid}`}
        </Typography>
      </Stack>
      {details.status === auctionStatus.LIVE ? (
        <>
          <Stack direction="row" spacing={1}>
            <Button
              variant="outlined"
              disabled={placeBidStatus === 'loading'}
              onClick={() => {
                onPlaceBid(500);
              }}
              sx={bidButtonStyle}
            >
              <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography
                  fontSize={7}
                  component="span"
                  sx={{ lineHeight: 1, verticalAlign: 'baseline' }}
                >
                  AED
                </Typography>
                <Typography component="span" sx={{ lineHeight: 1 }}>
                  500
                </Typography>
              </Box>
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                onPlaceBid(1000);
              }}
              disabled={placeBidStatus === 'loading'}
              sx={bidButtonStyle}
            >
              <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography
                  fontSize={7}
                  component="span"
                  sx={{ lineHeight: 1, verticalAlign: 'baseline' }}
                >
                  AED
                </Typography>
                <Typography component="span" sx={{ lineHeight: 1 }}>
                  1000
                </Typography>
              </Box>
            </Button>
            <TextField
              id="outlined-basic"
              label="Custom Value"
              size="small"
              variant="outlined"
              onChange={(e) => setBidCustomValue(Number(e.target.value) || 0)}
              value={bidCustomValue || ''}
            />
          </Stack>
          <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: 'primary', // Blue background color
              color: 'white', // White text color
              fontSize: '16px', // Slightly larger font size
              padding: '10px 40px', // Padding for larger button
              borderRadius: '25px', // Rounded corners
              textTransform: 'none', // Disable uppercase text
              boxShadow: '0px 4px 6px rgba(0, 123, 255, 0.1)', // Subtle shadow
              '&:hover': {
                backgroundColor: '#0056b3', // Darker blue on hover
                boxShadow: '0px 6px 8px rgba(0, 123, 255, 0.3)' // Slightly stronger shadow on hover
              }
            }}
            disabled={placeBidStatus === 'loading'}
            onClick={() => {
              if (bidCustomValue) {
                onPlaceBid(bidCustomValue);
              }
            }}
          >
            Place a Bid
          </Button>
        </>
      ) : (
        <>
          <Stack direction="row" spacing={2}>
            <SelectWinnerModalButton auction={details} />
            {/* <Button
              disabled={!auction?.current_bid}
              variant="outlined"
              color="success"
              onClick={onSellAndContinue}
              sx={{
                backgroundColor: 'primary', // Blue background color
                color: 'ptimary', // White text color
                fontSize: '16px', // Slightly larger font size
                padding: '10px 40px', // Padding for larger button
                borderRadius: '25px', // Rounded corners
                textTransform: 'none', // Disable uppercase text
                boxShadow: '0px 4px 6px rgba(0, 123, 255, 0.1)', // Subtle shadow
                '&:hover': {
                  backgroundColor: 'primary',
                  // Darker blue on hover
                  boxShadow: '0px 6px 8px rgba(0, 123, 255, 0.2)' // Slightly stronger shadow on hover
                }
              }}
            >
              Sell
            </Button> */}
            <Button
              variant="contained"
              onClick={onSkipAndContinue}
              sx={{
                backgroundColor: 'primary', // Blue background color
                color: 'white', // White text color
                fontSize: '16px', // Slightly larger font size
                padding: '10px 40px', // Padding for larger button
                borderRadius: '25px', // Rounded corners
                textTransform: 'none', // Disable uppercase text
                boxShadow: '0px 4px 6px rgba(0, 123, 255, 0.1)', // Subtle shadow
                '&:hover': {
                  backgroundColor: '#0056b3', // Darker blue on hover
                  boxShadow: '0px 6px 8px rgba(0, 123, 255, 0.3)' // Slightly stronger shadow on hover
                }
              }}
            >
              Skip
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
}
