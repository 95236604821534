import React from "react";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";

export default function FormRadio({ name, control, label, options }) {
  const generateRadioOptions = () => {
    return options.map((singleOption) => (
      <FormControlLabel
        key={singleOption.value} // Add a key to avoid warnings in React
        value={singleOption.value}
        label={singleOption.label}
        control={<Radio />}
      />
    ));
  };

  return (

    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div style={{ width: "100%", paddingTop: 8 }}>
          <label>
            {label}
          </label>
          <RadioGroup value={value} onChange={onChange}>
            {generateRadioOptions()}
          </RadioGroup>
          {error && <FormHelperText error>{error.message}</FormHelperText>}
        </div>
      )}
    />

  );
}
