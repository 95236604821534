import React from "react";
import { Controller } from "react-hook-form";

export default function FormSwitch({ control, name, label, ...props }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <div
            className="custom-control custom-switch"
            style={{ display: "flex", alignItems: "center", height: "100%", justifyContent: "space-between" }}
          >
            <input
              id={name}
              type="checkbox"
              className="custom-control-input"
              checked={field.value || false}
              onChange={() => {
                field.onChange(!field.value);
              }}
            />
            <label className="custom-control-label" htmlFor={name}>
              {label}
            </label>
          </div>
        );
      }}
    />
  );
}
