import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import firebaseConfig from '../firebaseConfig.json'
// Import other Firebase services you need, like auth, storage, etc.



const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
// Export other Firebase services if needed

export { firebaseApp, db };