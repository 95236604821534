import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '../../../utils/axios';
import { debounce } from 'lodash';

const initialState = {
  data: [],
  totalCount: 0,
  perPage: 100,
  loading: false,
  error: null
};
const fetchLeads = createAsyncThunk('leads', (data) => {
  let url = `/device/api/v1/auth/get-lead`;
  return axios.post(url, data).then((response) => {
    return response.data;
  });
});

const rmSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    // setSelected: (state, action) => {
    //   const { index, value } = action.payload;
    //   // console.log(index);
    //   let data = state.data;
    //   data[index].check = value;
    //   state.data = data;
    // },
    // setAllSelected: (state, action) => {
    //   const { value } = action.payload;
    //   // console.log(value);
    //   let data = state.data;
    //   state.data = data.map((e) => {
    //     e.check = value;
    //     return e;
    //   });
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLeads.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchLeads.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data ?? null;
      state.totalCount = action.payload?.data?.paginator?.itemCount;
      state.perPage = action.payload?.data?.paginator?.perPage;
    });
    builder.addCase(fetchLeads.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  }
});

export default rmSlice.reducer;
// export const { setSelected, setAllSelected } = rolesSlice.actions;
export { fetchLeads };
