import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { debounce } from "lodash";
import { fetchVerticals } from "./verticalsSlice";

const initialState = {
  data: {},
  status: "init",
  error: null,
};
const createVerticals = createAsyncThunk("createVerticals", (data, { dispatch }) => {
  let url = `/device/api/v1/vertical/create`;
  // console.log(data);
  return axios.post(url, data).then((response) => {
    let data = {
      'query': {},
      "options": {
        page: 1,
        limit: 30,
        sort: '-createdAt'
      }
    }
    dispatch(fetchVerticals(data));
    return response.data;
  });
});

const createVerticalSlice = createSlice({
  name: "createVerticals",
  initialState,
  reducers: {
    resetCreateVerticals: (state, action) => {
      state.data = {};
      state.status = "init";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createVerticals.pending, (state) => {
      state.status = "loading";
      state.error = null;
      state.data = {};
    });
    builder.addCase(createVerticals.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.status = "success";
      state.data = action.payload.data;
    });
    builder.addCase(createVerticals.rejected, (state, action) => {
      state.status = "error";
      state.data = {};
      state.error = action.error.message;
    });
  },
});

export default createVerticalSlice.reducer;
export const { resetCreateVerticals } = createVerticalSlice.actions;
export { createVerticals };
