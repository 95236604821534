import { SvgIcon } from "@mui/material";

export default function CylinderIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg version="1.1" viewBox="0 0 2048 2048" xmlns="http://www.w3.org/2000/svg">
                <path transform="translate(635,307)" d="m0 0h709l15 3 16 6 11 6 12 9 6 5 7 8 9 13 8 16 5 19 1 7v21l-3 16-4 12-8 16-12 16h-2v2h-2v2l-14 11-14 8-21 7-15 2-177 1-1 101h146l13 2 13 4 10 4 16 10 13 12 8 9 8 13 6 13 4 13 2 17 1 79 3 10 6 8 8 6 8 3 9 1h59l55 1 15 3 16 6 14 8 13 11 9 9 10 15 7 15 4 14 2 14v178l67-1v-42l3-17 5-15 10-18 6-8h2l2-4 8-8 14-10 17-8 14-4 14-2h84l21 2 25 6 21 9 15 8 14 10 11 9 16 16 14 19 6 10 9 20 7 22 2 9 2-1v403l-2 1-4 17-8 22-8 16-10 15-10 13-16 16-16 12-15 9-16 8-18 6-17 4-14 2-15 1h-75l-16-2-17-5-16-8-13-10-14-14-9-14-7-14-4-15-2-13v-42h-67v142l-2 15-4 14-5 12-8 13-9 11-8 8-16 11-16 7-14 4-15 2h-738l-18-3-16-6-16-9-14-12-166-166-8-5-13-3-153-1-14-2-16-5-17-9-10-8-9-8-10-13-8-14-5-12-4-18-1-15v-168l-101-1v145l-2 15-5 15-8 16-9 12-5 6-8 7-13 9-17 8-19 5-6 1h-22l-16-3-16-6-16-9-14-12-8-9-9-14-6-12-6-19v-586h2l1-7 6-16 6-11 8-11 3-4h2l2-4 11-9 11-7 12-6 12-4 10-2 20-1 18 2 16 5 16 8 11 8 10 9 10 12 8 14 5 11 4 16 1 9v145h101v-132l1-17 4-18 7-17 9-14 11-12 10-9 16-10 19-7 11-2 12-1 111-1 10-3 8-6 5-6 4-11 3-26 5-18 8-16 12-16 8-8 15-11 14-7 11-4 17-3h181v-102h-179l-14-2-16-5-17-9-10-8-10-9-10-13-6-10-6-15-4-17-1-9v-11l3-19 5-15 7-14 8-11 12-13 13-10 15-8 16-5zm249 68-243 1-10 4-9 8-6 12-1 5v11l4 10 6 8 9 6 10 3h690l12-3 8-6 6-7 4-10 1-10-3-11-5-9-7-6-12-5-250-1zm4 137v102h203l1-6v-95l-1-1zm-244 171-9 2-10 7-6 8-3 7-1 5-2 21-5 19-8 17-9 13-4 4v2l-4 2-10 9-15 9-15 6-19 4-49 1h-59l-15 1-12 6-7 7-5 10-1 5v591l3 9 6 9 8 6 8 3 4 1 154 1 16 2 18 6 15 8 11 9 12 11 159 159 11 7 9 2h728l10-2 10-6 7-8 4-11v-731l-5-12-3-4h-2v-2l-10-6-7-2-111-1-19-2-16-5-16-8-10-7-10-9-8-9-7-11-7-14-4-13-2-11-1-20-1-69-5-12-8-8-10-5-4-1zm-550 137-9 4-8 7-5 7-3 9v559l4 10 6 8 9 6 11 3h8l11-4 9-6 6-9 3-12v-552l-2-9-5-9-9-8-10-4zm1714 204-11 2-10 6-7 8-4 9-1 10v478l2 11 6 10 10 8 9 3 14 1h25l50-1 17-4 15-6 13-8 11-9 9-9 9-13 7-14 4-12 3-18v-357l-3-19-6-17-8-14-7-9-7-8-14-11-12-7-19-7-12-2-11-1zm-1602 34-1 1v135h101v-136zm1433 103v272h66l1-1v-271z" />
            </svg>
        </SvgIcon>
    );
}
