import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '../../../utils/axios';
import { setAuctionsInAuctionsGroup } from './auctionGroupDetailsSlice';

const initialState = {
  data: null,
  status: 'init',
  totalCount: null,
  perPage: null,
  error: null,
  addItemStatus:'init',
  addItemError:null,
};
const fetchAddableItemsToGroup = createAsyncThunk(
  'auctions/fetchaddItemToGroup',
  (data) => {
    let url = `/device/api/v1/baseformvalues/list`;
    // console.log(url);
    return axios.post(url, data).then((response) => {
      return response.data.data;
    });
  }
);

const addItemsToGroup = createAsyncThunk(
    'auctions/addItemToGroup',
    ({id,items},{dispatch}) => {
      let url = `/device/api/v1/auction-group/add-items/${id}`;
      // console.log(url);
      return axios.post(url, {items}).then((response) => {
        dispatch(setAuctionsInAuctionsGroup(response.data?.data))
        return response.data.data;
      });
    }
  );

const AddItemsToGroupSlice = createSlice({
  name: 'AddItemsToGroupSlice',
  initialState,
  reducers: {
    resetAddItemsToGroupSlice: (state) => {
      state.data = null;
      state.status = 'init';
      state.total = null;
      state.error = null;
      state.addItemStatus = 'init';
      state.addItemError = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAddableItemsToGroup.pending, (state) => {
      state.status = 'loading';
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAddableItemsToGroup.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.status = 'success';
      state.data = action.payload?.data;
      state.totalCount = action.payload?.paginator?.itemCount;
      state.perPage = action.payload?.paginator?.perPage;
    });
    builder.addCase(fetchAddableItemsToGroup.rejected, (state, action) => {
      state.loading = false;
      state.status = 'error';
      state.error = action.error.message;
    });
    builder.addCase(addItemsToGroup.pending, (state) => {
        state.addItemStatus = 'loading';
        state.loading = true;
        state.addItemError = null;
      });
      builder.addCase(addItemsToGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.addItemStatus = 'success';
      });
      builder.addCase(addItemsToGroup.rejected, (state, action) => {
        state.loading = false;
        state.addItemStatus = 'error';
        state.addItemError = action.error.message;
      });
  }
});

export const AddItemsToGroupReducer = AddItemsToGroupSlice.reducer;
export const { resetAddItemsToGroupSlice } = AddItemsToGroupSlice.actions;
export { fetchAddableItemsToGroup,addItemsToGroup };
