import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";

const initialState = {
  status: "init",
  loading: false,
  error: null,
};
const forceCancelAuction = createAsyncThunk("auctions/forceCancel", (id) => {
  let url = `/device/api/v1/auction/cancel/${id}`;
  // console.log(url);
  return axios.post(url).then((response) => {
    return response.data;
  });
});

const forceCancelAuctionSlice = createSlice({
  name: "forceCancel",
  initialState,
  reducers: {
    resetForceCancelAuction: (state) => {
      state.status = "init";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(forceCancelAuction.pending, (state) => {
      state.status = null;
      state.loading = true;
      state.error = null;
    });
    builder.addCase(forceCancelAuction.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.status = "success";
    });
    builder.addCase(forceCancelAuction.rejected, (state, action) => {
      state.loading = false;
      state.status = "error";
      state.error = action.error.message;
    });
  },
});

export default forceCancelAuctionSlice.reducer;
export const { resetForceCancelAuction } = forceCancelAuctionSlice.actions;
export { forceCancelAuction };
