import React, { useEffect, useState, useRef } from 'react';
import { Badge } from 'reactstrap';
import { describeArc } from '../../../utils/describeArc';
import msToTime from '../../../utils/msToTime';
import { colors } from '@mui/material';
import { auctionStatus } from '../../../utils/Utils';
import { useSocket } from '../../../utils/initializeSocket';

const LiveAuctionTimerWithScale = ({
  auction,
  startTime,
  duration,
  setAuctionStats,
  auctionStats,
}) => {
  const [time, setTime] = useState(1);
  const [percentage, setPercentage] = useState(0);
  const [percentColor, setPercentColor] = useState();
  const { serverTime, hasInitialSync, getCurrentTime, isConnected } = useSocket();

  // Update time when server time changes or using fallback
  useEffect(() => {
    if (!hasInitialSync) return;

    const currentTime = getCurrentTime();
    const endTime = startTime + duration;
    
    if (auctionStats === auctionStatus.LIVE) {
      let time = endTime - currentTime 
      setTime(time <0 ? 0 : time);
    } else if (auctionStats === auctionStatus.UPCOMING) {
      let time = startTime - currentTime
      setTime(time <0 ? 0 : time);
    }
  }, [serverTime, hasInitialSync, startTime, duration]);

  // Rest of your component code remains the same...
  useEffect(() => {
    let percent = (time / duration) * 100;
    setPercentage(percent);
    document.getElementById("shades")?.setAttribute("d", describeArc(0, 0, 45, 0, percent * 1.8));
    percent < 25
      ? setPercentColor("#800020")
      : percent < 50
        ? setPercentColor(colors.orange[700])
        : percent < 75
          ? setPercentColor(colors.yellow[700])
          : setPercentColor("#006400");
  }, [time]);
  
  // Optional: Add connection status indicator
  const renderConnectionStatus = () => {
    if (!hasInitialSync) {
      return (
        <Badge color="warning" className="ms-2">
          Syncing...
        </Badge>
      );
    }
    if (!isConnected) {
      return (
        <Badge color="warning" className="ms-2">
          Offline - Using Estimated Time
        </Badge>
      );
    }
    return null;
  };

  // Update your render methods to include connection status
  const renderTimer = (label) => (
    <div>
      <Badge className="badge-dim badge-lg" color="primary">
        {label}: {msToTime(time)}
      </Badge>
      {renderConnectionStatus()}
    </div>
  );

  switch (auctionStats) {
    case auctionStatus.SOLD:
      return (
        <>
          <Badge className="badge-dim badge-lg" color="success">
            Congratulations
          </Badge>
          <p>Item has successfully auctioned</p>
        </>
      );

    case auctionStatus.COMPLETED:
      if (auction?.winner) {
        return (
          <>
            <Badge className="badge-dim badge-lg" color="success">
              Congratulations
            </Badge>
            <p>Item has successfully auctioned</p>
          </>
        );
      }
      return (
        <Badge className="badge-dim badge-lg" color="success">
          Auction has finished!
        </Badge>
      );
    case auctionStatus.LIVE:
      return (
        <React.Fragment>
          <div className="clock-container d-flex align-items-center justify-content-center flex-column">
            <div>
              <svg
                width="240"
                viewBox="0 0 115 75"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mt-4"
              >
                <g id="Group 80">
                  <path
                    id="segment4"
                    d="M16.1877 21.9536C14.9318 20.873 13.0306 21.0107 12.0169 22.3212C4.70174 31.7786 0.515977 43.2849 0.0446937 55.232C-0.0206144 56.8876 1.34769 58.2146 3.00441 58.1935L3.94626 58.1815C5.60298 58.1605 6.91963 56.799 6.9968 55.1439C7.47492 44.8895 11.0651 35.0204 17.2875 26.8556C18.2918 25.5378 18.1577 23.6486 16.9017 22.568L16.1877 21.9536Z"
                    fill={percentColor}
                  />
                  <path
                    id="segment3"
                    d="M55.3253 3.04341C55.2592 1.38787 53.862 0.09128 52.2122 0.243605C40.3064 1.34282 29.0363 6.12819 19.9769 13.9309C18.7215 15.0121 18.684 16.9179 19.8292 18.1152L20.4803 18.7959C21.6255 19.9932 23.5192 20.0278 24.7823 18.9555C32.6084 12.3121 42.2749 8.2077 52.49 7.19072C54.1387 7.02658 55.429 5.64012 55.3629 3.98458L55.3253 3.04341Z"
                    fill={percentColor}
                  />
                  <path
                    id="segment2"
                    d="M96.0373 18.9627C97.2089 17.7911 97.2138 15.885 95.9828 14.7761C87.0991 6.77388 75.9381 1.73907 64.0598 0.375357C62.4138 0.186381 60.9881 1.45158 60.8852 3.10523L60.8267 4.04534C60.7238 5.699 61.9829 7.11381 63.6276 7.31457C73.8175 8.55848 83.3903 12.8769 91.0668 19.6926C92.3057 20.7927 94.1997 20.8003 95.3713 19.6287L96.0373 18.9627Z"
                    fill={percentColor}
                  />
                  <path
                    id="segment1"
                    d="M112 57.5C113.657 57.5 115.008 56.1557 114.922 54.5011C114.299 42.561 109.967 31.1088 102.532 21.7453C101.501 20.4477 99.5987 20.3342 98.3566 21.4308L97.6505 22.0542C96.4084 23.1507 96.2983 25.0415 97.3193 26.3464C103.645 34.4314 107.361 44.2539 107.969 54.5015C108.067 56.1554 109.401 57.5 111.058 57.5H112Z"
                    fill={percentColor}
                  />
                  <path
                    id="shades"
                    fill="url(#paint0_linear_1405_2)"
                    fillOpacity="0.23"
                    style={{
                      transform: `translate(${50}%, ${75}%)`
                    }}
                  />
                  <path
                    id="speedometer-arrow"
                    className="speedometer-arrow"
                    style={{
                      transformOrigin: '0% 0%',
                      transform: `translate(${50}%, ${75}%) rotate(${(100 - percentage) * -1.8}deg)`
                    }}
                    d="M2 0L0 2L45 0L0 -2Z"
                    fill={percentColor}
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_1405_2"
                    x1="59.3273"
                    y1="10.4145"
                    x2="10.6158"
                    y2="56.3264"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor={percentColor} />
                    <stop
                      offset="0.421875"
                      stopColor={percentColor}
                      stopOpacity="0.55"
                    />
                    <stop
                      offset="0.708333"
                      stopColor={percentColor}
                      stopOpacity="0.32"
                    />
                    <stop offset="1" stopColor={percentColor} stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <Badge className="badge-dim badge-lg" color="primary">
               {renderTimer("Auction Time Left")}
            </Badge>
          </div>
        </React.Fragment>
      );
    case auctionStatus.UPCOMING:
      return (
        <React.Fragment>
          <div>
            <Badge className="badge-dim badge-lg" color="primary">
            {renderTimer("Auction will be live in")}
            </Badge>
          </div>
        </React.Fragment>
      );
    default:
      return <div></div>;
  }
};

export default LiveAuctionTimerWithScale;
