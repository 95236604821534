import React, { useEffect, useState } from 'react';
import {
    Button,
    Typography,
    DialogActions,
    Dialog,
    Slide,
    DialogTitle,
    DialogContent
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { listAsDirectSale } from '../../../redux/features/itemsSlice/itemDetailsSlice';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ListAsDirectSaleButton({ item }) {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { listAsDirectSaleStatus, listAsDirectSaleError } = useSelector(state => state.carDetails)

    const handleListAsDirectSale = () => {
        dispatch(listAsDirectSale({ id: item?.id }))
    };

    useEffect(() => {
        if (listAsDirectSaleStatus === 'success') {
            toast.success('Successfully listed as Direct Sale')
            setOpen(false)
        }
    }, [listAsDirectSaleStatus, listAsDirectSaleError])

    return (
        <div>
            <Button disabled={item.status === 'AUCTION_SCHEDULED' || item.status === 'AUCTION_LIVE' || item.sale_type === 'DIRECT_SALE' || item.status === 'SOLD' || item.status === 'RESERVED'} variant="outlined" color="primary" onClick={handleOpen} >
                List as Direct Sale
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="list-as-direct-sale-description"
            >
                <DialogTitle>Confirm Direct Sale Listing</DialogTitle>
                <DialogContent>
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                        Are you sure you want to list this auction item as a direct sale?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleListAsDirectSale} color="primary">
                        Confirm Direct Sale
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
