import { Box, Card, Stack, Typography } from '@mui/material';
import React from 'react';
import { Image } from '../../../components/Component';
import CylinderIcon from './CylinderIcon';
import MeterIcon from './MeterIcon';
import { Container } from 'reactstrap';

export default function AuctionItem({ auction }) {
  return (
    <Card elevation={1} sx={{ padding: 2, width: 1 }}>
      <Stack direction="row" spacing={2}>
        <Box borderRadius={2} height={100} width={100} border="hidden" overflow="hidden">
          <Image image={auction.item.title_image} size={100} width={100} />
        </Box>
        <Stack justifyContent="center" spacing={0.5}>


          <Typography variant="subtitle2" fontWeight="bold">
            <span style={{ padding: 4, backgroundColor: 'purple', color: 'white', borderRadius: 12, marginRight: 8 }}>{auction.sequence_position}</span>
            {auction.item.make} {auction.item.model}
          </Typography>

          <Stack direction="row" spacing={1}>
            <Typography color="gray" variant="body1" fontSize={12}>
              Starting Price:
            </Typography>
            <Typography variant="body1" color="primary" fontSize={12} fontWeight="bold">
              AED {auction.item.auction_starting_price}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <CylinderIcon sx={{ fontSize: 20 }} />
              <Typography fontSize={9}>{auction.item?.cylinder ?? 4} CYL</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <MeterIcon sx={{ fontSize: 20 }} />
              <Typography fontSize={9}>{auction.item?.Mileage ?? 4} Km</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Card >
  );
}
