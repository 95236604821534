import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { onCancelAuction } from "./auctionGroupDetailsSlice";

export const cancelAuctionGroup = createAsyncThunk("auction-groups/delete", (data,{dispatch}) => {
    let url = `/device/api/v1/auction-group/${data}`;

    return axios.delete(url).then((response) => {
        dispatch(onCancelAuction())
        return response.data;
    });
});