import React, { useEffect, useState, useCallback } from 'react';
import { Image } from '../Component';
import PdfViewer from '../image/PdfPreview';

const FileIcon = React.memo(({ extension, color = '#000000' }) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="50" rx="5" fill={color} />
    <text x="20" y="30" fontSize="12" fill="white" textAnchor="middle">
      {extension.toUpperCase()}
    </text>
  </svg>
));

const getIconColor = (extension) => {
  const colorMap = {
    pdf: '#FF0000',
    doc: '#295495', docx: '#295495',
    xls: '#1F6E43', xlsx: '#1F6E43',
    ppt: '#D24625', pptx: '#D24625',
    txt: '#4A4A4A',
    zip: '#FFA500', rar: '#FFA500',
    mp3: '#1DB954', wav: '#1DB954',
    mp4: '#FF1493', avi: '#FF1493', mov: '#FF1493'
  };
  return colorMap[extension.toLowerCase()] || '#808080';
};

export default function RenderPreview({ file, onRemove }) {
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(true);

  const generatePreview = useCallback(() => {
    setLoading(true);
    try {
      if (typeof file === 'string') {
        const fileExtension = file.split('.').pop().toLowerCase();
        setPreview(
          <div>
            <div style={{ position: 'relative', width: '100px', height: '100px' }}>
              {/\.(jpg|jpeg|png|webp)$/i.test(file) ? (
                <Image image={file} alt={file} />
              ) : /\.(pdf)$/i.test(file) ? (
                <PdfViewer file={file} />
              ) : (
                <FileIcon extension={fileExtension} color={getIconColor(fileExtension)} />
              )}
              <button onClick={onRemove} style={{ position: 'absolute', top: 0, right: 0, background: 'red', color: 'white', border: 'none', cursor: 'pointer' }}>
                X
              </button>
            </div>
          </div>
        );
      } else if (file instanceof File) {
        const fileType = file.type.split('/')[0];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        const objectUrl = URL.createObjectURL(file);

        setPreview(
          <div>
            <div style={{ position: 'relative', width: '100px', height: '100px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100px' }}>
                {fileType === 'image' ? (
                  <img src={objectUrl} alt="Preview" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                ) : (
                  <FileIcon extension={fileExtension} color={getIconColor(fileExtension)} />
                )}
                <div style={{ marginTop: '5px', fontSize: '12px', textAlign: 'center', wordBreak: 'break-word', maxWidth: '100px' }}>
                  {file.name}
                </div>
              </div>
              <button onClick={onRemove} style={{ position: 'absolute', top: 0, right: 0, background: 'red', color: 'white', border: 'none', cursor: 'pointer' }}>
                X
              </button>
            </div>
          </div>
        );

        return () => URL.revokeObjectURL(objectUrl);
      }
    } catch (error) {
      console.error('Error generating preview:', error);
      setPreview(
        <div style={{ width: '50px', height: '50px', backgroundColor: '#f0f0f0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          Error
        </div>
      );
    } finally {
      setLoading(false);
    }
  }, [file, onRemove]);

  useEffect(() => {
    const cleanup = generatePreview();
    return cleanup;
  }, [generatePreview]);

  if (loading) {
    return (
      <div style={{ width: '50px', height: '50px', backgroundColor: '#f0f0f0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        Loading...
      </div>
    );
  }

  return preview;
}