import { SvgIcon } from "@mui/material";

export default function MeterIcon(props) {
    return (
        <SvgIcon {...props}>

            <svg version="1.1" viewBox="0 0 2048 2048" xmlns="http://www.w3.org/2000/svg">
                <path transform="translate(991,211)" d="m0 0h67l50 3 40 4 49 7 41 8 35 8 36 10 29 9 27 9 37 14 31 13 28 13 40 20 22 12 17 10 22 13 17 11 15 10 19 13 16 12 15 11 15 12 14 11 14 12 15 13 10 9 8 7 13 13 8 7 19 19 7 8 13 13 7 8 12 14 13 15 11 14 13 16 9 12 13 18 12 17 8 12 11 17 12 19 12 21 15 26 8 15 11 23 13 27 14 33 12 31 11 31 12 39 11 41 8 36 8 44 6 43 4 39 2 1v123h-427l-1-84 1-2h341l-1-4-4-50-5-40-8-45-11-49-12-42-12-37-17-44-13-30-14-29-15-30-14-24-9-16-12-19-16-24-11-16-14-19-14-18-8-10-12-14-8-10-2-4h-2l-7-8-22-24-31-31-8-7-15-14-11-9-14-12-11-9-13-10-16-12-18-13-24-16-17-11-23-14-16-9-24-13-17-9-23-11-34-15-36-14-22-8-38-12-41-11-41-9-39-7-50-6-38-3-26-1h-63l-38 2-41 4-42 6-33 6-47 11-42 12-36 12-32 12-26 11-27 12-27 13-44 24-20 12-19 12-18 12-19 13-21 16-19 14-9 8-11 9-13 11-13 12-12 11-10 9-26 26-1 2h-2l-2 4-7 7-7 8-12 13-8 10-9 11-11 13-14 19-12 16-11 16-16 24-16 26-11 19-13 24-8 15-11 23-12 26-8 20-9 23-9 25-11 34-12 43-8 35-8 43-5 37-4 43-2 23h338l3 1v84l-1 1-424 1-2-1v-113l2-12 4-39 5-37 6-35 8-38 10-40 12-40 11-33 11-29 17-41 17-36 14-28 10-17 6-11 16-28 15-23 14-21 12-17 13-18 13-17 9-11 11-14 2-3h2l2-4 9-10 9-11 6-7h2l2-4 13-13 7-8 27-27 8-7 12-12 11-9 10-9 11-9 9-8 14-11 26-20 20-14 11-8 20-13 25-16 24-14 23-13 27-14 24-12 34-15 27-11 30-11 35-12 42-12 36-9 44-9 44-7 42-5 43-3z" />
                <path transform="translate(1657,926)" d="m0 0 4 2 13 13 8 7 12 12v2l4 2v2l4 2v2h2l6 7 4 3v2l3 1 2 4-6 7h-2v2h-2v2h-2v2h-2l-2 4-466 466-3 2 1 4 9 14 10 18 10 22 8 25 5 22 3 23v42l-2 17-4 21-7 24-9 22-8 16-10 17-10 14-10 13-12 13-13 13-8 7-19 14-18 11-14 8-24 10-22 7-28 6-28 3h-21l-21-2-23-4-25-7-21-8-17-8-20-12-14-10-14-11-5-4v-2l-4-2-14-14-7-8-14-18-12-19-10-18-10-24-5-15-6-25-3-22-1-11v-25l2-21 4-22 7-25 10-25 8-16 12-20 12-16 9-11 14-15 14-13 13-10 16-11 17-10 16-8 20-8 24-7 20-4 16-2h44l29 4 23 6 20 7 18 8 17 9 17 11 5 1 2-4h2l2-4 472-472h2l2-4zm-645 525-17 2-19 4-22 8-21 11-14 10-10 9-8 7-9 10-8 11-8 13-8 15-7 19-5 21-2 17v26l2 18 5 20 8 22 8 15 10 15 12 14 13 13 16 12 13 8 20 9 15 5 25 5 9 1h28l21-3 19-5 20-8 15-8 16-11 14-12 10-10 10-13 7-11 10-19 7-19 5-22 2-19v-17l-2-20-3-16-8-24-8-17-11-17-11-14-13-13-13-10-14-9-17-9-16-6-25-6-18-2z" />
                <path transform="translate(995,467)" d="m0 0h51l45 3 35 4 32 5 38 8 31 8 33 10 33 12 27 11 20 9 25 12 23 12 23 13 21 13 24 16 15 11 17 13 13 10 28 24 3 2v2l4 2 13 13 8 7 11 11 7 8 7 7v2h2l3 4-1 4-8 7-5 6-7 6-5 6-7 6-5 6-7 6-5 5-7 8-7-6-16-17-11-11-8-7-9-9-11-9-14-12-18-14-18-13-24-16-21-13-25-14-21-11-33-15-33-13-36-12-29-8-29-7-33-6-38-5-45-3h-58l-30 2-35 4-35 6-31 7-30 8-37 12-33 13-18 8-30 14-28 15-15 9-18 11-31 21-26 20-11 9-14 12-8 7-16 15-28 28-7-6-12-11-8-7-13-12-8-7-14-12-3-3 2-5 7-6 7-8 26-26 8-7 14-13 11-9 14-12 19-14 13-10 13-9 24-16 28-17 18-10 27-14 29-14 40-16 34-12 41-12 35-8 37-7 38-5 37-3z" />
            </svg>
        </SvgIcon>
    );
}
