import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid2
} from '@mui/material';
import { toast } from 'react-toastify';

import { axios } from '../../../utils/axios';
import FormAutoComplete from '../../../components/form/FormAutoComplete';
import { FormSelect, FormTextField } from '../../../components/Component';
import FormNumberInput from '../../../components/form/FormNumberInput';
import FormMultiSelect from '../../../components/form/FormMultiSelect';
import FormPriceInput from '../../../components/form/FormPriceInput';
import FormMultiImageUpload from '../../../components/form/FormMultiImageUpload';
import {
  resetItemEditState,
  updateItem
} from '../../../redux/features/itemsSlice/itemDetailsSlice';
import _ from 'lodash';

// Constants moved outside component to prevent recreating on each render
const FUEL_OPTIONS = ['Gasoline', 'Diesel', 'CNG', 'Electric'];
const ENGINE_OPTIONS = ['1.0L', '1.5L', '2.0L', '3.0L', '4.0L'];
const CYLINDER_OPTIONS = ['1', '2', '3', '4', '5', '6', '8', '12'];
const TRANSMISSION_OPTIONS = ['Manual', 'Automatic', 'Semi-Automatic'];
const SPECS_OPTIONS = [
  'GCC',
  'American',
  'Canadian',
  'EU',
  'Japan',
  'Korean',
  'Chinese',
  'Other'
];
const INSURANCE_OPTIONS = ['Yes', 'No'];
const TYPE_OPTIONS = [
  'SUV',
  'Coupe',
  'Sedan',
  'Cross Over',
  'Hard Top Convertible',
  'Soft Top Convertible',
  'Pick Up Truck',
  'Hatchback',
  'Sports Car'
];
const DRIVE_TRAIN_OPTIONS = ['4WD', 'AWD', '2WD'];
const TAG_OPTIONS = ['CLEAN_SLATE', 'RUN_&_DRIVE'];

const generateVehicleYears = (startYear = 1980) => {
  const currentYear = new Date().getFullYear();
  return Array.from(
    { length: currentYear - startYear + 1 },
    (_, i) => currentYear - i
  );
};

const YEAR_OPTIONS = generateVehicleYears();

const EditCarModal = ({ isOpen, handleClose, carDetails }) => {
  const dispatch = useDispatch();
  const { editItemStatus, editItemError } = useSelector(
    (state) => state.carDetails
  );

  const defaultValues = useMemo(() => carDetails || {}, [carDetails]);

  const { handleSubmit, control, watch, reset } = useForm({ defaultValues });

  useEffect(() => {
    if (carDetails) {
      reset({
        make: '',
        model: '',
        trim: '',
        vin: '',
        engine: '',
        cylinder: '',
        year: '',
        fuel: '',
        transmission: '',
        specs: '',
        insurance: '',
        type: '',
        drive_train: '',
        mileage: '',
        tag: [],
        auction_starting_price: '',
        direct_sale_price: '',
        images: [],
        ...carDetails
      });
    }
  }, [carDetails, reset]);

  const fetchAutocompleteOptions = useCallback(async (data) => {
    try {
      const response = await axios.post('/device/api/v1/autocomple/list', data);
      return response.data?.data?.data || [];
    } catch (error) {
      console.error('Error fetching autocomplete options:', error);
      return [];
    }
  }, []);

  const loadOptions = useCallback(
    async (inputValue, key, reference) => {
      const options = await fetchAutocompleteOptions({
        query: {
          key,
          search: inputValue,
          referenceValue: reference,
          referenceKey: reference ? 'CAR_MAKE' : undefined
        },
        options: { pagination: false }
      });
      return options.map(({ value }) => ({ label: value, value }));
    },
    [fetchAutocompleteOptions]
  );

  const handleEditCar = useCallback(
    async (data) => {
      try {
        const payload = Object.entries(data).reduce((acc, [key, value]) => {
          if (value !== carDetails[key]) {
            acc[key] = value;
          }
          return acc;
        }, {});

        // dispatch(updateItem({ id: carDetails.id, data: payload }));
        await dispatch(
          updateItem({ id: carDetails.id, data: payload })
        ).unwrap();
        toast.success('Car details updated successfully!');
        handleClose();
        dispatch(resetItemEditState());
      } catch (e) {
        toast.error(
          `Error: ${editItemError ?? 'Failed to update car details'}`
        );
        dispatch(resetItemEditState());
      }
    },
    [carDetails, dispatch, editItemStatus, editItemError, handleClose]
  );

  const formFields = useMemo(
    () => [
      {
        Component: FormAutoComplete,
        props: {
          name: 'make',
          label: 'Make',
          loadOptions: (v) => loadOptions(v, 'CAR_MAKE')
        },
        size: 6
      },
      {
        Component: FormAutoComplete,
        props: {
          name: 'model',
          label: 'Model',
          loadOptions: (v) => loadOptions(v, 'CAR_MODEL', watch('make'))
        },
        size: 6
      },
      {
        Component: FormTextField,
        props: { name: 'trim', label: 'Trim' },
        size: 6
      },
      {
        Component: FormTextField,
        props: { name: 'vin', label: 'VIN' },
        size: 6
      },
      {
        Component: FormSelect,
        props: { name: 'engine', label: 'Engine', options: ENGINE_OPTIONS },
        size: 6
      },
      {
        Component: FormSelect,
        props: {
          name: 'cylinder',
          label: 'Cylinder',
          options: CYLINDER_OPTIONS
        },
        size: 6
      },
      {
        Component: FormSelect,
        props: { name: 'year', label: 'Year', options: YEAR_OPTIONS },
        size: 6
      },
      {
        Component: FormSelect,
        props: { name: 'fuel', label: 'Fuel', options: FUEL_OPTIONS },
        size: 6
      },
      {
        Component: FormSelect,
        props: {
          name: 'transmission',
          label: 'Transmission Type',
          options: TRANSMISSION_OPTIONS
        },
        size: 6
      },
      {
        Component: FormSelect,
        props: { name: 'specs', label: 'Specs', options: SPECS_OPTIONS },
        size: 6
      },
      {
        Component: FormSelect,
        props: {
          name: 'insurance',
          label: 'UAE insurance',
          options: INSURANCE_OPTIONS
        },
        size: 6
      },
      {
        Component: FormSelect,
        props: { name: 'type', label: 'Type', options: TYPE_OPTIONS },
        size: 6
      },
      {
        Component: FormSelect,
        props: {
          name: 'drive_train',
          label: 'Drive Train',
          options: DRIVE_TRAIN_OPTIONS
        },
        size: 6
      },
      {
        Component: FormNumberInput,
        props: { name: 'mileage', label: 'Mileage', unit: 'KM' },
        size: 6
      },
      {
        Component: FormMultiSelect,
        props: { name: 'tag', label: 'Tags', options: TAG_OPTIONS },
        size: 6
      },
      {
        Component: FormPriceInput,
        props: {
          name: 'auction_starting_price',
          label: 'Auction Starting Price'
        },
        size: 6
      },
      {
        Component: FormPriceInput,
        props: { name: 'direct_sale_price', label: 'Direct Sale Price' },
        size: 6
      },
      {
        Component: FormMultiImageUpload,
        props: { name: 'images', label: 'Images',maxFilesLength:50 },
        size: 12
      }
    ],
    [loadOptions, watch]
  );

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>Edit Car Details</DialogTitle>

      <DialogContent
        dividers
        sx={{
          maxHeight: '70vh',
          overflowY: 'auto'
        }}
      >
        <Grid2 container spacing={3}>
          {formFields.map((field, index) => (
            <Grid2 key={index} item size={field.size}>
              <field.Component control={control} {...field.props} />
            </Grid2>
          ))}
        </Grid2>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" type="button">
          Cancel
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          disabled={editItemStatus === 'loading'}
          onClick={handleSubmit(handleEditCar)}
        >
          {editItemStatus === 'loading' ? 'Saving...' : 'Save Changes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(EditCarModal, _.isEqual);
