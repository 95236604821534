import React, { useContext, useEffect } from "react";
import Content from "../../../layout/content/Content";
import { Button,  Grid2,  Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchSoldCars, fetchUnsoldCars, resetCompletedAuctions } from "../../../redux/features/auctionSlice/completedAuctionGroupSlice";
import { useDispatch } from "react-redux";
import AuctionItem from "./AuctionItem";
import VirtualizedAuctionList from "./NextAuctionsList";

export const CompletedScreen = ({auctionGroup}) =>{
    const {soldCars,unsoldCars} = useSelector((state)=>state.completedAuctionGroup)
    const dispatch = useDispatch()

    useEffect(()=>{
      if(auctionGroup?.id){
       dispatch( fetchSoldCars(auctionGroup?.id));
       dispatch(fetchUnsoldCars(auctionGroup?.id))
      }
       return ()=>{
        dispatch( resetCompletedAuctions())
       }
    },[auctionGroup?.id, dispatch])
    
    return (
    <React.Fragment>
            <Content >
  <Grid2 container spacing={2}>
      <Grid2 size={12}>
          <Paper elevation={1} sx={{ padding: 4, textAlign: 'center', marginTop: 4 }}>
            <Typography variant="h4" gutterBottom>
              Auction Group Completed
            </Typography>
            <Typography variant="body1" paragraph>
              All auctions in this group have ended. Thank you for participating!
            </Typography>
            <Button variant="contained" color="primary" component={Link} to="/auction-groups">
              View All Auction Groups
            </Button>
          </Paper>
      </Grid2>
        {/* Sold Cars Section */}
        <Grid2 size={6}>
            <Paper elevation={1} sx={{ padding: 3 }}>
              <Typography variant="h5" gutterBottom>
                Sold Cars
              </Typography>

                  <VirtualizedAuctionList auctions={soldCars ??[]} noAuctionText={" No sold cars available."} />

            </Paper>
          </Grid2>

          {/* Unsold Cars Section */}
          <Grid2 size={6}>
            <Paper elevation={1} sx={{ padding: 3 }}>
              <Typography variant="h5" gutterBottom>
                Unsold Cars
              </Typography>
             
                <VirtualizedAuctionList auctions={unsoldCars ?? []} noAuctionText={" No unsold cars available."} />

          
            </Paper>
          </Grid2>
  </Grid2>
  </Content>
  </React.Fragment>
)};