import React, { useState, useEffect } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import {
  SortableContext,
  useSortable,
  rectSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import AuctionItem from './AuctionItem.jsx';
import { X } from 'lucide-react';

const SortableItem = ({ id, auction, onDelete }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box',
    position: 'relative'
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(id);
  };

  return (
    <div ref={setNodeRef} style={style}>
      <div {...attributes} {...listeners} style={{ cursor: 'move' }}>
        <AuctionItem auction={auction} />
      </div>
      <button
        style={{
          position: 'absolute',
          top: '0px',
          right: '0px',
          backgroundColor: '#f56565',
          color: 'white',
          border: 'none',
          borderRadius: '50%',
          padding: '4px',
        }}
        onClick={handleDelete}
        onMouseEnter={(e) => (e.target.style.backgroundColor = '#e53e3e')} // Hover effect
        onMouseLeave={(e) => (e.target.style.backgroundColor = '#f56565')} // Hover effect
      >
        <X size={16} />
      </button>
    </div>
  );
};

const AuctionReorder = ({ auctions = [], onLayoutChange = () => {} }) => {
  const [items, setItems] = useState(auctions);

  useEffect(() => {
    if (JSON.stringify(auctions) !== JSON.stringify(items)) {
      setItems(auctions);
    }
  }, [auctions]);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        const newItems = [...items];
        const [reorderedItem] = newItems.splice(oldIndex, 1);
        newItems.splice(newIndex, 0, reorderedItem);

        const updatedItems = newItems.map((item, index) => ({
          ...item,
          sequence_position: index + 1
        }));

        onLayoutChange(updatedItems);
        return updatedItems;
      });
    }
  };

  const handleDelete = (id) => {
    setItems((items) => {
      const updatedItems = items.filter((item) => item.id !== id);
      onLayoutChange(updatedItems);
      return updatedItems;
    });
  };

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext
        items={items.map((item) => item.id)}
        strategy={rectSortingStrategy}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
            gap: '16px'
          }}
        >
          {items.map((item) => (
            <SortableItem
              key={item.id}
              id={item.id}
              auction={item}
              onDelete={handleDelete}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default AuctionReorder;
