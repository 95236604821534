import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { debounce } from "lodash";

const initialState = {
    data: [],
    totalCount: 0,
    perPage: 10,
    loading: false,
    error: null,
    selected:[]
};
const fetchDirectSales = createAsyncThunk("directSales/list", (data) => {
    let url = `/device/api/v1/direct-sale/list`;
    return axios.post(url, data).then((response) => {
        return response.data;
    });
});

const directSalesSlice = createSlice({
    name: "directSales",
    initialState,
    reducers: {
        setSelected: (state, action) => {
            const { id, value } = action.payload;
            if (value) {
              state.selected.push(id);
            } else {
              state.selected = state.selected.filter(v => v !== id);
            }
          },
          setAllSelected: (state, action) => {
            const { value } = action.payload;
            if (value) {
              state.selected = state.data?.map((e) => e.id)
            } else {
              state.selected = []
            }
          },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDirectSales.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.data = [];
        });
        builder.addCase(fetchDirectSales.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload?.data?.data;
            state.totalCount = action.payload?.data?.paginator?.itemCount;
            state.perPage = action.payload?.data?.paginator?.perPage;
        });
        builder.addCase(fetchDirectSales.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
});

const debounced = debounce(({ ...arg }, dispatch) => dispatch(fetchDirectSales({ ...arg })), 500);
const debouncedFetchDirectSales =
    ({ ...args }) =>
        (dispatch) =>
            debounced({ ...args }, dispatch);

export default directSalesSlice.reducer;
export const { setSelected, setAllSelected } = directSalesSlice.actions;
export { fetchDirectSales, debouncedFetchDirectSales, };
