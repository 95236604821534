import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '../../../utils/axios';

const initialState = {
  newId: null,
  status: 'init',
  loading: false,
  error: null
};

const repostAuction = createAsyncThunk('auctions/repost', (data) => {
  const { id, body } = data;
  let url = `/device/api/v1/auction/repost/${data?.auction_id ?? id}`;
  console.log(url)
  return axios.post(url, body).then((response) => {
    return response.data;
  });
});
const repostAuctionSlice = createSlice({
  name: 'repostAuction',
  initialState,
  reducers: {
    resetRepostAuction: (state) => {
      state.status = 'init';
      state.error = null;
      state.newId = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(repostAuction.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = {};
    });
    builder.addCase(repostAuction.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.status = 'success';
      state.newId = action.payload.data;
    });
    builder.addCase(repostAuction.rejected, (state, action) => {
      state.loading = false;
      state.status = 'error';
      state.error = action.error.message;
    });
  }
});

export default repostAuctionSlice.reducer;
export const { resetRepostAuction } = repostAuctionSlice.actions;
export { repostAuction };
