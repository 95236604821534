import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getImage } from "../../utils/uploadImageToS3";
import { Modal } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Pagination,Thumbs } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import placeHolderImage from "../../images/product/a.png"; // You can replace this with your placeholder image

SwiperCore.use([Navigation, Thumbs]);

const Carousal = ({ images, id }) => {
  const [imageUrls, setImageUrls] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [isOpenFull, setIsOpenFull] = useState(false);
  const [fullViewImage, setFullViewimage] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  useEffect(() => {
    let isMounted = true;

    async function getImages() {
      let urls = [];
      for (let i = 0; i < (images?.length ?? 0); i++) {
        const k = images[i];
        if (k && k !== "defaults/default_image.png") {
          const obj = await getImage(k);
          if (obj) urls.push({ key: k.key, image: obj, url: k });
        }
      }
      if (isMounted) setImageUrls(urls);
    }

    getImages();

    return () => {
      isMounted = false;
    };
  }, [images]);

  const handleStepChange = (step) => setActiveStep(step);

  const toggleFull = () => setIsOpenFull(!isOpenFull);

  return (
    <Box position="relative" minHeight="100%" width="100%" className="carousel-large-image">
      {/* Main Swiper */}
      <Swiper
        spaceBetween={10}
        navigation
        thumbs={{ swiper: thumbsSwiper }}
        onSlideChange={(swiper) => handleStepChange(swiper.activeIndex)}
        className="main-slider"
      >
        {imageUrls.map((step, index) => (
          <SwiperSlide key={index}>
            <img
              onClick={() => {
                setFullViewimage(step);
                toggleFull();
              }}
              style={{
                height: "36rem",
                width: "100%",
                objectFit: "cover",
                background: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif",
              }}
              src={step.image || placeHolderImage}
              alt={`carousel-img-${index}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Thumbnail Swiper */}
      {imageUrls.length > 0 && (
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={5}
          freeMode
          watchSlidesProgress
          className="thumbnail-slider"
          style={{
            marginTop: "1rem",
            position: "absolute",
            bottom: "0",
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
          }}
        >
          {imageUrls.map((step, index) => (
            <SwiperSlide key={index}>
              <Box
                component="img"
                src={step.image || placeHolderImage}
                sx={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  borderRadius: 2,
                  border: activeStep === index ? 2 : 0,
                  borderColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.main",
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
                alt={`thumb-img-${index}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      {/* Full Image Modal */}
      {isOpenFull && (
        <Modal isOpen={isOpenFull} toggle={toggleFull} size="xl">
          <div style={{ width: "100%", height: "100%", objectPosition: "center" }}>
            <img
              src={fullViewImage?.image || placeHolderImage}
              style={{ objectFit: "fill", height: "100%", width: "100%" }}
              alt="full-view"
            />
          </div>
        </Modal>
      )}
    </Box>
  );
};

export default Carousal;
